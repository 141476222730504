/*
 * The Optimizely User Profile Map is defined as the following
 * [docs](https://docs.developers.optimizely.com/full-stack/docs/implement-a-user-profile-service-javascript)
{
  "title": "UserProfile",
  "type": "object",
  "properties": {
    "user_id": {"type": "string"},
    "experiment_bucket_map": {
      "type": "object",
      "patternProperties": {
      "^[a-zA-Z0-9]+$": {"type": "object",
      "properties": {
        "variation_id": {"type":"string"}},
        "required": ["variation_id"]}
       }
    }
  },
  "required": ["user_id", "experiment_bucket_map"]
}
*/
import { createLogger } from './logger';

const { log } = createLogger('[USER-PROFILE-SERVICE]');

export async function createUserProfileService(uuid, gateway) {
  let cache = {
    user_id: uuid,
    experiment_bucket_map: {},
  };

  const key = `optimizelyUserProfile_${uuid}`;

  const userProfileService = {
    lookup: () => {
      log('LOOKUP CACHE:', cache);
      return cache;
    },
    save: (userProfileMap) => {
      log('SAVING', userProfileMap);
      cache = userProfileMap;
      if (gateway) {
        gateway.setJSON(key, userProfileMap);
      }
    },
  };

  if (gateway) {
    const value = await gateway.getJSON(key);
    log('GOT SOMETHING FROM THE CACHE', value);
    if (value) {
      cache = value;
    }
  }

  return userProfileService;
}
