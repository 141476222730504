import isPromise from '@updater-core/interactors/shell/tracker/utils/isPromise';
import { startSpan, tagError } from '@updater-core/lib/tracing';

const withTracing = (name, fn, parentSpan) => (...args) => {
  const span = startSpan(name, {
    childOf: parentSpan,
  });

  let returnVal;

  try {
    returnVal = fn(...args, parentSpan);
  } catch (error) {
    tagError(span, error);
    throw error;
  } finally {
    if (isPromise(returnVal)) {
      span.setTag('async', true);
      returnVal
        .then((result) => {
          span.setTag('result', result);
          span.finish();
          return result;
        })
        .catch((error) => {
          tagError(span, error);
          span.finish();
          throw error;
        });
    } else {
      span.setTag('async', false);
      span.setTag('result', returnVal);
      span.finish();
    }
  }

  return returnVal;
};

export default withTracing;