import { Environment } from '../types';
import { DC_STAGING } from './keys';

export const demo: Environment = {
  name: 'demo',
  endpoints: {
    api: {
      url: '//api.demo.updater.com',
    },
    cloudinary: 'https://res.cloudinary.com/updater-staging/image/upload',
    optimizely:
      'https://cdn.optimizely.com/datafiles/DnMhFQjARNtYrHoyJ6Dajb.json',
    eventstream: 'https://eventstream-api.demo.updatron.com/xyz',
    sure: {
      protections:
        'https://api.trysureapp.com/api/partner/v1.1/protections/renters',
      partner: 'https://api.trysureapp.com/api/partner/v1',
    },
    leasingDeskWidget: 'https://ri-widget-uat-demo.realpage.com/widget',
    verticalOne: 'https://api-staging.verticalone.com/v1',
    dollyUi: 'https://book-staging.dolly.com/',
    consumerAppUrl: 'https://consumer-app.staging.updater.com',
  },
  keys: {
    smartystreets: {
      authToken: '4537425833973228200',
    },
    eventstream: {
      authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQHVwZGF0ZXIuY29tIiwic3ViIjoiY2xpY2tzdHJlYW0iLCJpYXQiOjE1NDAzMTAzMjUsImlzcyI6IlVwZGF0ZXIifQ.dj2xw2PwGO4RqEMg19kRFAa0_Yfe-Pgoiw4FeLyeiNE',
    },
    selectquote: {
      authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQHVwZGF0ZXIuY29tIiwic3ViIjoiY2xpY2tzdHJlYW0iLCJpYXQiOjE1NDAzMTAzMjUsImlzcyI6IlVwZGF0ZXIifQ.dj2xw2PwGO4RqEMg19kRFAa0_Yfe-Pgoiw4FeLyeiNE',
    },
    stripe: 'pk_test_KZhy8xHQcPTYedpuiPekDh8w',
    segment: 'XBrMiZqQW8dEiFlyMfnPjsbdgwBoicVr',
    sentry: 'https://450a46f3c11b4c44998f757aadc3d9fa@sentry.io/89704',
    sure: 'Token pRLyZdYX3EsuNm6en7rBhFDokItCqfiMgb8x9SQAcwazTpHG0',
    sureStripe: 'pk_test_D2bzH96t4WolPemRrrmHXjoT',
    dc: {
      authorization: DC_STAGING,
    },
  },
};
