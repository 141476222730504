import { createLogger } from './logger';

const { log } = createLogger('[GATEWAY][STORAGE]');

export function createGateway({ storageGateway }) {
  const facade = {
    get: () => {
      const moverAppData = storageGateway.getJSON('upd_mover');
      const additionalData = storageGateway.getJSON('mover_app_session_data');
      let session = {};

      if (
        moverAppData &&
        moverAppData.session &&
        Object.keys(moverAppData.session).length > 0
      ) {
        session = {
          ...moverAppData.session,
          ...additionalData,
        };
      }

      log('get', session);

      return session;
    },
    set: (newSession) => {
      let moverAppData = storageGateway.getJSON('upd_mover');
      const session = facade.get();

      if (!moverAppData) {
        moverAppData = {};
      }

      if (!moverAppData.session) {
        moverAppData.session = {};
      }

      session.uid = newSession.uid;
      session.client = newSession.client;
      session['access-token'] = newSession['access-token'];

      log('set', newSession);

      storageGateway.setJSON('upd_mover', {
        ...moverAppData,
        session,
      });
      storageGateway.setJSON('mover_app_session_data', {
        user: {
          uuid: newSession.user?.uuid,
          current_move: {
            id: newSession.user?.current_move_id,
          },
        },
      });
    },
    clear: () => {
      const moverAppData = storageGateway.getJSON('upd_mover');

      if (!moverAppData?.session) {
        return;
      }

      moverAppData.session = {};

      storageGateway.setJSON('upd_mover', moverAppData);
      storageGateway.setJSON('mover_app_session_data', moverAppData);
    },
  };

  return facade;
}
