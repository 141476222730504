export const RECEIVE = 'received';
export const PAGE = 'viewed';
export const VIEW = 'viewed';
export const CHANGE = 'changed';
export const CLICK = 'clicked';
export const SELECT = 'selected';
export const CLOSE = 'closed';
export const SUBMIT = 'submitted';
export const DISPLAY = 'displayed';
export const TRACK = 'tracked';
export const UPDATE = 'updated';
export const MEASURE = 'measured';
export const BOUNCE = 'bounced';
export const ERROR = 'errored';
export const WARNING = 'warned';
export const PURCHASE = 'purchased';
export const DECLINE = 'declined';
export const ENROLL = 'enrolled';
export const UPLOAD = 'uploaded';
export const ENGAGE = 'engaged';
export const FAIL = 'failed';
export const PULL = 'pulled';

// These are legacy verbs that we'd like to avoid
// using. For that reason, we have not documented them.
export const TRY = 'tried';
export const VERIFY = 'verified';
export const CREATE = 'created';
export const SAVE = 'saved';
export const CONFIRM = 'confirmed';
export const CANCEL = 'canceled';
export const DISMISS = 'dismissed';
export const ENTER = 'entered';
export const LOAD = 'loaded';
export const VALIDATE = 'validated';
export const SENT = 'sent';
export const RESENT = 'resent';
export const ADD = 'added';
export const REMOVE = 'removed';
export const OPEN = 'opened';

export const VERBS = [
  RECEIVE,
  PAGE,
  VIEW,
  CHANGE,
  CLICK,
  SELECT,
  CLOSE,
  SUBMIT,
  DISPLAY,
  TRACK,
  UPDATE,
  MEASURE,
  BOUNCE,
  ERROR,
  WARNING,
  TRY,
  VERIFY,
  CREATE,
  SAVE,
  CONFIRM,
  CANCEL,
  DISMISS,
  ENTER,
  LOAD,
  VALIDATE,
  SENT,
  RESENT,
  ADD,
  REMOVE,
  OPEN,
  DECLINE,
  PURCHASE,
  ENROLL,
  UPLOAD,
  ENGAGE,
];

export const TRACKED_EVENTS = {
  change: {
    verb: CHANGE,
    detail: true,
  },
  click: {
    verb: CLICK,
    detail: false,
  },
  close: {
    verb: CLOSE,
    detail: false,
  },
  submit: {
    verb: SUBMIT,
    detail: true,
  },
};
