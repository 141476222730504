import merge from 'lodash/merge';
import { getAttributes } from '@updater-core/lib/tracker';
import {
  differenceInDays,
  differenceInMinutes,
  differenceInSeconds,
  format,
} from 'date-fns';
import { flushSpans } from '@updater-core/lib/tracing';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import { ENVIRONMENT } from '@updater-core/lib/environment';
import { createLogger } from './logger';
import { eventStreamAttributes } from './selectors';

const { log } = createLogger('[ACTION]');

function setCookie(name, value, expiration, domain) {
  document.cookie = `${name}=${value}; expires=${expiration}; path=/; domain=${domain}`;
}

function handleRedirect(dependencies, response, initialPath = '') {
  const { window } = dependencies;
  const { location, token } = response;
  const { search, port } = window.location;
  const locationPort = port ? `:${port}` : '';
  const separator = search.includes('?') ? '&' : '?';

  const { clientSessionId } = getAttributes();
  if (clientSessionId) {
    const KEY = 'upd_clientSessionId';
    const expiration = new Date();
    // Expire this cookie after 60 seconds
    expiration.setSeconds(expiration.getSeconds() + 60);
    const domain = `.${location.split('.').slice(1).join('.')}`;
    setCookie(KEY, clientSessionId, expiration.toGMTString(), domain);
  }

  let locationWithSubdomain = location;

  if (!location.includes('.')) {
    locationWithSubdomain += `.${window.location.hostname}`;
  }

  return flushSpans().then(() => {
    const url =
      locationWithSubdomain +
      locationPort +
      initialPath +
      (token ? `${search}${separator}t=${token}` : '');
    window.location.href = url;
  });
}

/**
 * Consumer App session cookie data is managed here. The consumer app uses
 * cookies so we keep them in sync here with the mover app local storage session
 */
const ROOT_DOMAIN = `${ENVIRONMENT.cookieRootDomain || 'updater.com'}`;
function clearSessionCookies() {
  /** Remove cookie data for security */
  Cookies.remove('access-token', { path: '/', domain: ROOT_DOMAIN });
  Cookies.remove('client', { path: '/', domain: ROOT_DOMAIN });
  Cookies.remove('uid', { path: '/', domain: ROOT_DOMAIN });
  Cookies.remove('currentUserId', { path: '/', domain: ROOT_DOMAIN });
  Cookies.remove('currentUserUUID', { path: '/', domain: ROOT_DOMAIN });
}

export const transferAuthSessionDataToCookies = (userSession) => {
  if (!isEmpty(userSession)) {
    const {
      'access-token': accessToken,
      client,
      uid,
      user: { id, uuid } = {},
    } = userSession || {};

    // leaving in case we have to debug this in fs
    console.log('TRANSFERING SESSION');
    if (accessToken && client && uid && uuid && id) {
      console.log('TRANSFERING SESSION WITH DATA');
      // Set cookies
      Cookies.set('access-token', accessToken, {
        domain: ROOT_DOMAIN,
        expires: 7,
      });
      Cookies.set('client', client, {
        domain: ROOT_DOMAIN,
        expires: 7,
      });
      Cookies.set('uid', uid, { domain: ROOT_DOMAIN, expires: 7 });
      Cookies.set('currentUserId', id, { domain: ROOT_DOMAIN, expires: 7 });
      Cookies.set('currentUserUUID', uuid, { domain: ROOT_DOMAIN, expires: 7 });
    }
  }
};

export const checkAndTransferCookieDataToSession = (storageGateway) => {
  const accessToken = Cookies.get('access-token');
  const client = Cookies.get('client');
  const uid = Cookies.get('uid');

  console.log('~~~~~~ RECLAIMING SESSION FROM COOKIES ~~~~~~', uid);
  if (accessToken && client && uid) {
    storageGateway.set({ 'access-token': accessToken, client, uid });
  } else {
    console.log(
      `~~~~~~ FAILED TO RECLAIM SESSION:
      token: ${Boolean(accessToken)}
      client: ${Boolean(client)}
      uid: ${Boolean(client)}
    ~~~~~`,
      uid
    );
  }
};

function getSessionMetadata(kind = 'fetch', body) {
  // TODO: Remove this expiry metadata once we have found the root cause
  // of "session dissapearing" issue
  let sessionMetadata = {};
  if (body.expiry) {
    try {
      const expiry = format(body.expiry * 1000, `yyyy-MM-dd HH:mm:ss xxx`);
      const current = format(Date.now(), `yyyy-MM-dd HH:mm:ss xxx`);
      const daysTillExpiry = differenceInDays(body.expiry * 1000, Date.now());
      const minutesTillExpiry = differenceInMinutes(
        body.expiry * 1000,
        Date.now()
      );
      const secondsTillExpiry = differenceInSeconds(
        body.expiry * 1000,
        Date.now()
      );

      log(`upd.session.${kind}.expiry`, expiry);
      log(`upd.session.${kind}.current`, current);
      log(
        `upd.session.${kind}.distance.to.expiry: ${daysTillExpiry} days, ${minutesTillExpiry} minutes.`
      );
      sessionMetadata = {
        expiry,
        current,
        daysTillExpiry,
        minutesTillExpiry,
        secondsTillExpiry,
      };
    } catch (ignore) {
      // eslint-disable-next-line no-console
      console.error(ignore);
    }
  }
  return sessionMetadata;
}

export function clearSession({ store, remoteGateway, storageGateway }) {
  return (session, parentSpan) => {
    log('upd.session.event.clear');
    // TODO: We should probably store the actual
    // authentication state and only clear that
    // instead of removing all session data
    // For now
    // - persist the metadata and attributes for logging
    // - persist "reset" flag if user is resetting PW
    // - persist "initialized" flag if the interactor was inited
    const { initialized, reset, sessionMetadata, ...state } =
      store.getState() || {};
    clearSessionCookies();
    store.clearState();
    let persistedState;
    /* 
        Temporary - session metadata and event stream attributes for logging 
        and digging into bugs around users signing in with expired sessions 
      */
    if (state.uid) {
      // Use a try/catch here in case there are bugs in eventStreamAttributes
      // Dont blow up session deletion for logging
      try {
        persistedState = {
          sessionMetadata,
          eventStreamAttributes: eventStreamAttributes(state),
        };
      } catch (ignore) {
        // Don't need to worry about this erroring out
        // since its just to aid in debugging
        // eslint-disable-next-line no-console
        console.error(ignore);
      }
    }

    if (reset || initialized) {
      persistedState = {
        ...persistedState,
        ...(reset && { reset }),
        ...(initialized && { initialized }),
      };
    }
    if (persistedState) {
      store.setState(persistedState);
    }

    return remoteGateway
      .clearSession(session, parentSpan)
      .then(() => {
        if (storageGateway) {
          storageGateway.clear();
        }
        log('upd.session.event.clear.succeeded');
        parentSpan?.log({
          message: 'session clear success',
        });
        return true;
      })
      .catch(() => {
        log('upd.session.event.clear.failed');
        parentSpan?.log({
          message: 'session clear failed',
        });
        return false;
      });
  };
}

export function setSession({ storageGateway }) {
  return async (session) => storageGateway.set(session);
}

export function createSession(dependencies) {
  return ({ email, password, token, redirectPath }, parentSpan) => {
    const { remoteGateway, storageGateway, store } = dependencies;

    log('upd.session.event.create');

    return remoteGateway
      .createSession(
        {
          email,
          password,
          token,
        },
        parentSpan
      )
      .then(async ([body, response]) => {
        if (remoteGateway.isRedirectResponse(response)) {
          log('upd.session.event.create.redirect');
          parentSpan?.log({
            message: 'session create redirect',
            email,
            redirectPath,
          });
          clearSession(dependencies)({}, parentSpan);
          await handleRedirect(dependencies, body, redirectPath);
          return true;
        }
        if (remoteGateway.isSuccessResponse(response)) {
          log('upd.session.event.create.succeeded');
          parentSpan?.log({
            message: 'session create success',
            email,
          });
          const sessionMetadata = getSessionMetadata('create', body);
          storageGateway.set(body);
          store.setState({
            ...body,
            sessionMetadata,
          });
          transferAuthSessionDataToCookies(store.getState());
          return true;
        }
        log('upd.session.event.create.failure');
        parentSpan?.log({
          message: 'session create failure',
          email,
        });
        clearSession(dependencies)({}, parentSpan);
        store.setState(body);
        return false;
      });
  };
}

// eslint-disable-next-line func-style
export const fetchSession = (dependencies) => (parentSpan) => {
  const { remoteGateway, storageGateway, store } = dependencies;

  log('upd.session.event.fetch');

  /** Gets cookie data and transfers to local storage - coming into site from re-direct */
  try {
    checkAndTransferCookieDataToSession(storageGateway);
  } catch (ex) {
    console.error('Failed to checkAndTransferCookieDataToSession', { ex });
  }

  const session = storageGateway.get();

  function makeResponse(success, props) {
    return {
      success,
      ...props,
    };
  }

  return remoteGateway
    .fetchSession(session, parentSpan)
    .then(async ([body, response, _request, token]) => {
      if (remoteGateway.isRedirectResponse(response)) {
        log('upd.session.event.fetch.redirect');
        parentSpan?.log?.({
          message: 'session fetch redirect',
        });
        console.log('~~~~ GOT SESSION REDIRECT CLEARING SESSION ~~~~~');
        clearSession(dependencies)(session, parentSpan);
        await handleRedirect(dependencies, body);
        return makeResponse(false);
      }
      if (remoteGateway.isSuccessResponse(response)) {
        log('upd.session.event.fetch.succeeded');
        parentSpan?.log?.({
          message: 'session fetch succeeded',
        });
        const sessionMetadata = getSessionMetadata('fetch', body);
        if (sessionMetadata?.secondsTillExpiry < 0) {
          log('upd.session.event.fetch.expired');
          parentSpan?.log?.({
            message: 'session expired upon fetch',
          });
          console.log('~~~~ SESSION EXPIRED ON FETCH; CLEARNING~~~~~');
          clearSession(dependencies)(session, parentSpan);
          return makeResponse(false);
        }
        storageGateway.set(body);
        store.setState({
          ...body,
          sessionMetadata,
        });
        transferAuthSessionDataToCookies(store.getState());
        return makeResponse(true, { token });
      }
      log('upd.session.event.fetch.failure');
      parentSpan?.log?.({
        message: 'session fetch failed',
      });

      console.log('~~~~ SESSION FETCH FAILED; CLEARNING~~~~~');
      console.log(
        `~~~~ UNAUTHORIZED? ${remoteGateway.isUnauthorizedResponse(
          response
        )} ~~~~`
      );
      try {
        console.log(`~~~~ BODY? ${JSON.stringify(response.body)} ~~~~`);
      } catch (e) {
        console.log(`~~~~ FAILED TO STRINGIFY BODY ~~~~~`);
      }

      clearSession(dependencies)(session, parentSpan);
      return makeResponse(false);
    });
};

export function init(dependencies) {
  return (parentSpan) =>
    fetchSession(dependencies)(parentSpan).then((result) => {
      const { store } = dependencies;

      store.setState({ initialized: true });

      return result;
    });
}

export function setResetPassword({ store }) {
  return (reset) => {
    store.setState({
      reset,
    });
  };
}

export function setForgotPassword({ store }) {
  return (forgot) => {
    store.setState({
      forgot,
    });
  };
}

export function setUUID({ store }) {
  return (uuid) => {
    store.setState(
      merge({}, store.getState(), {
        user: {
          uuid,
        },
      })
    );
  };
}

export function setEmail({ store }) {
  return (email) => {
    store.setState(
      merge({}, store.getState(), {
        user: {
          email,
        },
      })
    );
  };
}

export function setVerified({ store }) {
  return (verified) => {
    const state = store.getState();
    store.setState({
      user: {
        ...state.user,
        current_move: {
          ...state.user.current_move,
          is_verified: verified,
        },
      },
    });
  };
}
