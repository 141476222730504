import { Environment } from '../types';
import { DC_STAGING } from './keys';

export const updatron: Environment = {
  name: 'updatron',
  endpoints: {
    api: {
      url: '//api.staging.updater.com',
    },
    cloudinary: 'https://res.cloudinary.com/updater-staging/image/upload',
    optimizely:
      'https://cdn.optimizely.com/datafiles/PV8bgAg9tPhvowmGw4zz4X.json',
    eventstream: 'https://eventstream-api.sandbox.updatron.com/xyz',
    sure: {
      protections:
        'https://api.trysureapp.com/api/partner/v1.1/protections/renters',
      partner: 'https://api.trysureapp.com/api/partner/v1',
    },
    leasingDeskWidget: 'https://ri-widget-uat-demo.realpage.com/widget',
    verticalOne: 'https://api-staging.verticalone.com/v1',
    dollyUi: 'https://book-staging.dolly.com/',
    dc: 'https://api.staging.bridgevine.io/v6',
    tokenService: {
      url: 'https://v8vy0byloh.execute-api.us-east-1.amazonaws.com',
    },
  },
  keys: {
    firebase: {
      apiKey: 'AIzaSyCuCxM5wfGBc90RHxCQUf2SrFLFknt1z3w',
      authDomain: 'updater-web-push-staging.firebaseapp.com',
      databaseURL: 'https://updater-web-push-staging.firebaseio.com',
      projectId: 'updater-web-push-staging',
      storageBucket: 'updater-web-push-staging.appspot.com',
      messagingSenderId: '1032981526758',
      appId: '1:1032981526758:web:636f0adbdec98c5bd46327',
      measurementId: 'G-GHX52JRFNH',
    },
    // firebase cloud messaging
    firebase_cm: {
      vapidKey:
        'BOkvsPk3yeoJhfJBWOFYdZwryzRE-I3oOEBDEHMmH26dgpQtJDQxpKfjQzgs2MIfcySn5jLWDQFNvdSYeFSZ_ZQ',
    },
    // Staging smartystreets key
    smartystreets: {
      authToken: '4537425833973228200',
    },
    eventstream: {
      authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQHVwZGF0ZXIuY29tIiwic3ViIjoiY2xpY2tzdHJlYW0iLCJpYXQiOjE1NDAzMTAzNzMsImlzcyI6IlVwZGF0ZXIifQ.QvQewiy9QX031Iw2Zo9uD-zE-le42EXxeM-_1RkmoU8',
    },
    stripe: 'pk_test_KZhy8xHQcPTYedpuiPekDh8w',
    segment: 'XBrMiZqQW8dEiFlyMfnPjsbdgwBoicVr',
    sentry: 'https://9593b71c96944d34965c760f1ce50681@sentry.io/53544',
    sure: 'Token pRLyZdYX3EsuNm6en7rBhFDokItCqfiMgb8x9SQAcwazTpHG0',
    sureStripe: 'pk_test_D2bzH96t4WolPemRrrmHXjoT',
    dc: {
      authorization: DC_STAGING,
    },
  },
  debug: {
    eventTracker: true,
  },
};
