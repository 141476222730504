import gql from 'graphql-tag';
import { fetchGraphQLQuery } from '@updater-core/lib/graphql';
import { ENVIRONMENT } from '@updater-core/lib/environment';
import { createGateway as createLocalStorageGateway } from '@updater-core/gateways/local-storage';
import { createGateway as createStorageGateway } from '@updater-core/interactors/shell/session/storage.gateway';
import { createQuickSetupGateway } from '@updater-apps/quick-setup/gateways/quick-setup';
import { ERROR, TRACK } from '@updater-core/lib/tracker';
import session from '@updater-core/interactors/shell/session';
import tracker from '@updater-core/interactors/shell/tracker';

const prodQuery = gql`
  query isExperimentFeatureEnabled($feature: String!, $attributes: JSON) {
    isExperimentFeatureEnabled(feature: $feature, attributes: $attributes) {
      result
    }
  }
`;
const debugQuery = gql`
  query isExperimentFeatureEnabled($feature: String!, $attributes: JSON) {
    isExperimentFeatureEnabled(feature: $feature, attributes: $attributes) {
      result
      logs
    }
  }
`;
const FEATURE_NAME = 'quick_setup';
const LOCAL_STORAGE_KEY = `upd_feature.${FEATURE_NAME}`;
export async function fetchQuickSetupFeatureFlag() {
  const localStorageGateway = createLocalStorageGateway();
  const sessionStorageGateway = createStorageGateway({
    storageGateway: localStorageGateway,
  });

  // The user object might be empty so include the email in details
  const userEmail = sessionStorageGateway.get()?.uid;

  tracker.actions.track({
    domain: 'quick_setup',
    object: 'optimizely_get',
    verb: TRACK,
    details: {
      userEmail,
    },
  });

  const localOverride = localStorageGateway.getJSON(LOCAL_STORAGE_KEY);
  if (localOverride && typeof localOverride.enabled === 'boolean') {
    return localOverride.enabled;
  }

  return fetchGraphQLQuery({
    url: `${ENVIRONMENT.endpoints.api.url}/graphql`,
    query: ENVIRONMENT?.debug?.backendOptimizely ? debugQuery : prodQuery,
    headers: {
      ...sessionStorageGateway.get(),
    },
    variables: {
      feature: FEATURE_NAME,
    },
  })
    .then((response) => {
      const result = response?.data?.isExperimentFeatureEnabled?.result;
      const logs = response?.data?.isExperimentFeatureEnabled?.logs;
      tracker.actions.track({
        domain: 'quick_setup',
        object: 'optimizely_response',
        verb: TRACK,
        details: {
          inQuickSetup: result,
          optimizelyLogs: logs,
          userEmail,
        },
      });
      return result;
    })
    .catch(() => {
      tracker.actions.track({
        domain: 'quick_setup',
        object: 'optimizely_get',
        verb: ERROR,
        details: {
          userEmail,
        },
      });
      return false;
    });
}

async function fetchIsDoneWithQuickSetup() {
  const localStorageGateway = createLocalStorageGateway();
  const sessionStorageGateway = createStorageGateway({
    storageGateway: localStorageGateway,
  });

  const gateway = createQuickSetupGateway({
    session: {
      getState: () => sessionStorageGateway.get(),
      selectors: session.selectors,
    },
  });

  window.gateway = gateway;

  return gateway.fetchDone();
}

export async function redirectIntoQuickSetup() {
  if (
    window.location.pathname.startsWith('/n/') ||
    window.location.search.includes('skipqs') ||
    window.location.pathname.startsWith('/subscription-center')
  ) {
    return false;
  }
  const localStorageGateway = createLocalStorageGateway();
  const sessionStorageGateway = createStorageGateway({
    storageGateway: localStorageGateway,
  });
  // The user object might be empty so include the email in details
  const userEmail = sessionStorageGateway.get()?.uid;

  // TEMP: Not using "Global Redirect" at the moment because
  // Trying to keep the "top" of the file clean of dependencies as possible
  const [isInQuickSetup, isDoneWithQuickSetup] = await Promise.all([
    fetchQuickSetupFeatureFlag(),
    fetchIsDoneWithQuickSetup(),
  ]);

  const oneSecondTimeout = new Promise((resolve) => setTimeout(resolve, 1000));

  await Promise.race([
    tracker.actions.track({
      domain: 'quick_setup',
      object: 'redirect',
      verb: TRACK,
      details: {
        isInQuickSetup,
        isDoneWithQuickSetup,
        redirected: isInQuickSetup && !isDoneWithQuickSetup,
        userEmail,
      },
    }),
    oneSecondTimeout,
  ]);
  if (isInQuickSetup && !isDoneWithQuickSetup) {
    // Redirect link from email engagement, use the corresponding one in Next
    if (window.location.pathname.match(/\/tv-internet\/?$/)) {
      window.location.href = `${window.location.origin}/n/tv-internet`;
      return true;
    }

    window.location.href = `${window.location.origin}/n/quick-setup`;
    return true;
  }
  return false;
}
