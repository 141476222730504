import fetch from '@updater-core/lib/fetch';
import { ENVIRONMENT as env } from '@updater-core/lib/environment';
import { ERROR, track } from '@updater-core/lib/tracker';

// eslint-disable-next-line camelcase
function transformBody({ access_token, ...rest }) {
  return {
    'access-token': access_token,
    ...rest,
  };
}

function handleError(ex, source) {
  if (window.console && window.console.error) {
    console.error(ex); // eslint-disable-line no-console
  }
  track({
    verb: ERROR,
    object: `${source}_fetch_error`,
    details: {
      message: ex.message,
      stack: ex.stack,
      ...ex,
    },
  });
}

function getTokenFromQueryParams() {
  const query = decodeURIComponent(window.location.search.substr(1));
  if (!query) {
    return undefined;
  }
  const params = query.split('&').reduce((acc, keyValuePair) => {
    const split = keyValuePair.split('=');
    const [key, value] = split;
    return { ...acc, ...{ [key]: value } };
  }, {});

  return params.t;
}

export function isSuccessResponse(response) {
  const { status } = response;
  return status === 201 || status === 200;
}

export function isRedirectResponse(response) {
  const { status } = response;
  return status === 418;
}

export function isUnauthorizedResponse(response) {
  const { status } = response;
  return status === 401;
}

export function createGateway() {
  return {
    isSuccessResponse,
    isRedirectResponse,
    isUnauthorizedResponse,
    fetchSession: async (session, parentSpan) => {
      const token = getTokenFromQueryParams();
      const { uid, client } = session;

      if (!uid && !token) return [false, false];

      const method = token ? 'POST' : 'GET';
      const headers = token ? {} : { uid };
      const body = token
        ? {
            body: JSON.stringify({
              token,
            }),
          }
        : {};

      const request = {
        method,
        mode: 'cors',
        headers: {
          'content-type': 'application/json; charset=utf-8',
          app: 'mover',
          'access-token': session['access-token'],
          client,
          ...headers,
        },
        ...body,
        parentSpan,
      };

      return (
        fetch(`${env.endpoints.api.url}/v2/sessions`, request)
          .then((response) => Promise.all([response.json(), response]))
          // eslint-disable-next-line @typescript-eslint/no-shadow
          .then(([body, response]) => {
            if (
              !(
                isRedirectResponse(response) ||
                isSuccessResponse(response) ||
                isUnauthorizedResponse(response)
              )
            ) {
              throw body;
            }

            return [transformBody(body), response, request, token];
          })
          .catch((ex) => {
            handleError(ex, 'fetch_session');
            return [false, false];
          })
      );
    },
    createSession: ({ email, password, token }, parentSpan) =>
      fetch(`${env.endpoints.api.url}/v2/sessions`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'content-type': 'application/json; charset=utf-8',
          app: 'mover',
        },
        body: JSON.stringify(token ? { token } : { email, password }),
        parentSpan,
      })
        .then((response) => Promise.all([response.json(), response]))
        .then(([body, response]) => {
          if (
            !(
              isRedirectResponse(response) ||
              isSuccessResponse(response) ||
              isUnauthorizedResponse(response)
            )
          ) {
            throw body;
          }

          return [transformBody(body), response];
        })
        .catch((ex) => {
          handleError(ex, 'create_session');
          return [false, false];
        }),
    clearSession: async (session = {}, parentSpan) => {
      const { uid, client } = session;
      const accessToken = session['access-token'];

      if (!(uid && client && accessToken)) return undefined;

      return fetch(`${env.endpoints.api.url}/v2/sessions`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'content-type': 'application/json; charset=utf-8',
          app: 'mover',
          'access-token': accessToken,
          client,
          uid,
        },
        parentSpan,
      })
        .then(() => true)
        .catch((ex) => {
          handleError(ex, 'delete_session');
          return false;
        });
    },
  };
}
