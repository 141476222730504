/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AddressHomeSize {
  four_bed_plus = 'four_bed_plus',
  one_bed = 'one_bed',
  studio = 'studio',
  three_bed = 'three_bed',
  three_bed_plus = 'three_bed_plus',
  two_bed = 'two_bed',
}

export enum ClaimedPromotionCodeType {
  barcode = 'barcode',
  confirmation_code = 'confirmation_code',
  none = 'none',
  promo_code = 'promo_code',
}

export enum HomeServicesCallCenter {
  bridgevine = 'bridgevine',
  kandela = 'kandela',
}

export enum HomeServicesFlowPreference {
  EXCLUSIVE = 'EXCLUSIVE',
  FEATURED = 'FEATURED',
  SHOP = 'SHOP',
  TRANSFER = 'TRANSFER',
}

export enum HomeServicesProviderFeaturedKind {
  property_exclusive = 'property_exclusive',
  property_featured = 'property_featured',
  updater_featured = 'updater_featured',
}

export enum HomeServicesReservationStatus {
  RESERVED = 'RESERVED',
}

export enum InternetKind {
  BPL = 'BPL',
  CABLE = 'CABLE',
  DSL = 'DSL',
  FIBER = 'FIBER',
  SATELLITE = 'SATELLITE',
  WIRELESS = 'WIRELESS',
}

export enum InternetProductType {
  bpl = 'bpl',
  cable = 'cable',
  dsl = 'dsl',
  fiber = 'fiber',
  satellite = 'satellite',
  wireless = 'wireless',
}

export enum InternetSpeedTier {
  MAXIMUM = 'MAXIMUM',
  MINIMUM = 'MINIMUM',
  NORMAL = 'NORMAL',
}

export enum OfferValueTier {
  BAD = 'BAD',
  GOOD = 'GOOD',
  OK = 'OK',
}

export enum PhoneKind {
  CABLE = 'CABLE',
  FIBER = 'FIBER',
  LANDLINE = 'LANDLINE',
  SATELLITE = 'SATELLITE',
  VOIP = 'VOIP',
}

export enum PhoneProductType {
  cable = 'cable',
  fiber = 'fiber',
  landline = 'landline',
  satellite = 'satellite',
  voip = 'voip',
}

export enum PromotionPlacement {
  claim_go_redirect = 'claim_go_redirect',
  email = 'email',
  exclusive_offers = 'exclusive_offers',
  forward_mail = 'forward_mail',
  moverpass = 'moverpass',
  splash_page = 'splash_page',
}

export enum PromotionRedemptionMethodType {
  informational = 'informational',
  location = 'location',
  website = 'website',
}

export enum ServiceabilityCachingStrategy {
  aggressive = 'aggressive',
  conservative = 'conservative',
  none = 'none',
}

export enum ServiceabilityChargeType {
  activation = 'activation',
  early_cancellation = 'early_cancellation',
  installation = 'installation',
  long_term = 'long_term',
  short_term = 'short_term',
}

export enum ServiceabilityDisclaimerDisplayLocation {
  details = 'details',
  list_page = 'list_page',
  proximity = 'proximity',
}

export enum ServiceabilityInternetSpeedUnit {
  gbps = 'gbps',
  kbps = 'kbps',
  mbps = 'mbps',
}

export enum ServiceabilityProviderAvailability {
  available = 'available',
  unavailable = 'unavailable',
  unknown = 'unknown',
}

export enum ServiceabilityResultStatus {
  cache = 'cache',
  error = 'error',
  ok = 'ok',
}

export enum ServiceabilityTermUnit {
  month = 'month',
  year = 'year',
}

export enum TvKind {
  CABLE = 'CABLE',
  FIBER = 'FIBER',
  IPTV = 'IPTV',
  SATELLITE = 'SATELLITE',
}

export enum TvPackage {
  FAMILY = 'FAMILY',
  LATINO = 'LATINO',
  NEWS = 'NEWS',
  PREMIUM = 'PREMIUM',
  SPORTS = 'SPORTS',
}

export enum TvProductType {
  cable = 'cable',
  fiber = 'fiber',
  iptv = 'iptv',
  satellite = 'satellite',
}

export enum UtilityKinds {
  ELECTRIC = 'ELECTRIC',
  GAS = 'GAS',
  WATER = 'WATER',
  electric = 'electric',
  gas = 'gas',
  water = 'water',
}

export interface AddressInput {
  unit?: string | null;
  street: string;
  city: string;
  state: string;
  postalCode: string;
  homeSize?: AddressHomeSize | null;
}

/**
 * moveId or address required with address taking precedence.
 */
export interface AvailableOffersInput {
  moveId?: string | null;
  address?: AddressInput | null;
  providerCodes?: string[] | null;
  cachingStrategy?: ServiceabilityCachingStrategy | null;
  requestId?: string | null;
  suggestedAddressSelections?:
    | ServiceabilitySuggestedAddressSelections[]
    | null;
}

/**
 * moveId or address required with address taking precedence.
 */
export interface AvailableProductsInput {
  moveId?: string | null;
  address?: AddressInput | null;
  providerCodes?: string[] | null;
  cachingStrategy?: ServiceabilityCachingStrategy | null;
  requestId?: string | null;
  suggestedAddressSelections?:
    | ServiceabilitySuggestedAddressSelections[]
    | null;
  shopFlowVersions?: (string | null)[] | null;
}

export interface ClaimPromotionPublicInput {
  promotionId?: string | null;
  promotionCode?: string | null;
  userUuid?: string | null;
  source?: PromotionPlacement | null;
}

export interface DCOrderInformationInput {
  moveId: string;
  offerCode: string;
  providerCode: string;
  reservedOffer?: any | null;
}

export interface DollyMoveInput {
  moveId: string;
  toAddress: AddressInput;
  fromAddress: AddressInput;
  moveDate: any;
}

export interface DownloadMoverDocumentInput {
  itemId: number;
  documentId: string;
  utilityKind?: UtilityKinds | null;
}

export interface HomeServiceReservationUserInput {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export interface HomeServicesAddressInput {
  unit?: string | null;
  street: string;
  city: string;
  state: string;
  postalCode: string;
}

export interface HomeServicesBridgevineMetadataInput {
  sessionId: string;
  transactionId: string;
}

export interface HomeServicesCallCenterInput {
  name: HomeServicesCallCenter;
  outbound?: boolean | null;
}

export interface HomeServicesMetadataInput {
  bridgevine?: HomeServicesBridgevineMetadataInput | null;
  checkoutEnabled?: boolean | null;
}

export interface HomeServicesOfferInput {
  id: string;
  code: string;
  name: string;
  price?: number | null;
  term: string;
  valueScore?: number | null;
  valueTier?: OfferValueTier | null;
  provider: HomeServicesProviderInput;
  services: HomeServicesOfferServicesInput;
  promo?: HomeServicesOfferPromoInput | null;
  introductoryPrice?: HomeServicesOfferIntroductoryPriceInput | null;
  promotions?: (HomeServicesOfferPromotionInput | null)[] | null;
  disclaimer?: string | null;
  highlights?: (string | null)[] | null;
  extendedHighlights?: (string | null)[] | null;
  metadata?: any | null;
  tags?: (string | null)[] | null;
}

export interface HomeServicesOfferIntroductoryPriceInput {
  price?: number | null;
  term?: string | null;
  duration?: number | null;
  savingsPercent?: number | null;
}

export interface HomeServicesOfferPromoInput {
  price?: number | null;
  term?: string | null;
  duration?: number | null;
  savingsPercent?: number | null;
}

export interface HomeServicesOfferPromotionInput {
  promotion?: string | null;
  disclaimer?: string | null;
}

export interface HomeServicesOfferServicesInput {
  internet?: InternetServiceInput | null;
  tv?: TvServiceInput | null;
  phone?: PhoneServiceInput | null;
}

export interface HomeServicesPreferencesInput {
  internet: InternetPreferenceInput;
  tv: TvPreferenceInput;
  phone: PhonePreferenceInput;
  flow?: HomeServicesFlowPreference | null;
  updaterPropertyConfig?: HomeServicesPropertyConfigInput | null;
  propertyConfig?: HomeServicesPropertyConfigInput | null;
}

export interface HomeServicesPropertyConfigInput {
  allowSatellite?: boolean | null;
  providers?: (HomeServicesProviderInput | null)[] | null;
}

export interface HomeServicesProviderInput {
  id: string;
  name?: string | null;
  logo?: string | null;
  featured?: boolean | null;
  exclusive: boolean;
  featuredKind?: HomeServicesProviderFeaturedKind | null;
}

export interface InitiateDCSessionInput {
  moveId: string;
  providerCodes?: string[] | null;
  forceNewSession?: boolean | null;
}

export interface InternetPreferenceInput {
  speed?: InternetSpeedTier | null;
  serviceRequired?: boolean | null;
}

export interface InternetServiceInput {
  speed?: InternetSpeedInput | null;
  kind?: InternetKind | null;
}

export interface InternetSpeedInput {
  mbps: number;
  tier: InternetSpeedTier;
}

export interface MakeHomeServicesOrderInput {
  moveId: string;
  reservationCode: string;
  offer: HomeServicesOfferInput;
  contactInformation?: any | null;
  creditCheck?: any | null;
  security?: any | null;
  installation?: any | null;
  optIns?: any | null;
  promotionOptions?: any | null;
  payment: any;
  price: any;
}

export interface MakeHomeServicesReservationInput {
  checkoutEnabled?: boolean | null;
  moveId: string;
  offer: HomeServicesOfferInput;
  offers?: (HomeServicesOfferInput | null)[] | null;
  preferences: HomeServicesPreferencesInput;
  propertyConfig?: HomeServicesPropertyConfigInput | null;
  callCenter?: HomeServicesCallCenterInput | null;
  metadata?: HomeServicesMetadataInput | null;
  user?: HomeServiceReservationUserInput | null;
  toAddress?: HomeServicesAddressInput | null;
  fromAddress?: HomeServicesAddressInput | null;
}

export interface PhonePreferenceInput {
  landline?: boolean | null;
  serviceRequired?: boolean | null;
}

export interface PhoneServiceInput {
  kind?: PhoneKind | null;
}

export interface ServiceabilityReservationInput {
  moveId: string;
  products: any;
  metadata?: ServiceabilityReservationMetadata | null;
}

export interface ServiceabilityReservationMetadata {
  checkoutEnabled?: boolean | null;
}

export interface ServiceabilitySuggestedAddressSelections {
  providerCode?: string | null;
  providerAddressId: string;
}

export interface SubmitItemAnswerInput {
  itemId: number;
  submission: any;
  utilityKind?: UtilityKinds | null;
  finalSubmit?: boolean | null;
}

export interface TvChannelsInput {
  count: number;
}

export interface TvFeaturesInput {
  onDemand?: boolean | null;
  dvr?: boolean | null;
}

export interface TvNetworksInput {
  cinemax?: boolean | null;
  hbo?: boolean | null;
  showtime?: boolean | null;
  starz?: boolean | null;
}

export interface TvPreferenceInput {
  packages?: (TvPackage | null)[] | null;
  cable?: boolean | null;
  serviceRequired?: boolean | null;
}

export interface TvServiceInput {
  kind?: TvKind | null;
  channels?: TvChannelsInput | null;
  networks?: TvNetworksInput | null;
  features?: TvFeaturesInput | null;
  additionalLanguages?: (string | null)[] | null;
  shortDescription?: string | null;
  longDescription?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
