import { Environment } from '../types';
import { DC_PRODUCTION } from './keys';

export const production: Environment = {
  name: 'production',
  cookieRootDomain: 'updater.com',
  endpoints: {
    api: {
      url: '//api.updater.com',
    },
    cloudinary: 'https://res.cloudinary.com/updater-production/image/upload',
    salesforce: 'https://updater.secure.force.com/uws/services/apexrest',
    optimizely:
      'https://cdn.optimizely.com/datafiles/EGFQf1VKrjRj4FfMXSxb7L.json',
    eventstream: 'https://eventstream-api.prod.updatron.com/xyz',
    sure: {
      protections:
        'https://api.sureapp.com/api/partner/v1.1/protections/renters',
      partner: 'https://api.sureapp.com/api/partner/v1',
    },
    leasingDeskWidget: 'https://widget.erenterplan.com/widget',
    verticalOne: 'https://api-production.verticalone.com/v1',
    dollyUi: 'https://book.dolly.com/',
    dc: 'https://api.bridgevine.io/v6',
    tokenService: {
      url: 'https://v8vy0byloh.execute-api.us-east-1.amazonaws.com',
    },
    consumerAppUrl: 'https://app2.updater.com',
    bucketingServiceUrl: 'https://user-bucketing.updater.com',
  },
  keys: {
    firebase: {
      apiKey: 'AIzaSyBxJoQIuUQD0JQeDwEAMwXEuVAPLwDTABU',
      authDomain: 'updater-web-push.firebaseapp.com',
      databaseURL: 'https://updater-web-push.firebaseio.com',
      projectId: 'updater-web-push',
      storageBucket: 'updater-web-push.appspot.com',
      messagingSenderId: '150284425956',
      appId: '1:150284425956:web:41741e76e6a29641e9aaf1',
      measurementId: 'G-5GZY4BW9G1',
    },
    // firebase cloud messaging
    firebase_cm: {
      vapidKey:
        'BHPkEFJVlNznMnENXLveUmSk1Ipcc63JvyQAzWBmxwqwuyppXjiTsTWymcLDECh8pe9kRGhNH0c8BH6fMdv3uOQ',
    },
    smartystreets: {
      authToken: '4537425833155295759',
    },
    eventstream: {
      authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQHVwZGF0ZXIuY29tIiwic3ViIjoiY2xpY2tzdHJlYW0iLCJpYXQiOjE1NDAzMTA0MzUsImlzcyI6IlVwZGF0ZXIifQ.upFBkg9aQwCjfltNQW7MdxSkD595LyyDkXPpMjVmv5Q',
    },
    selectquote: {
      authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQHVwZGF0ZXIuY29tIiwic3ViIjoiY2xpY2tzdHJlYW0iLCJpYXQiOjE1NDAzMTA0MzUsImlzcyI6IlVwZGF0ZXIifQ.upFBkg9aQwCjfltNQW7MdxSkD595LyyDkXPpMjVmv5Q',
    },
    stripe: 'pk_live_stGFWdPN9H4YHnPwTzGXZqzK',
    segment: 'JtZ2IiMl8CNrrznGgZMV6mAzKvYCHPWr',
    sentry: 'https://8a0943f09702494baac02c9f5c2e6390@sentry.io/53561',
    lightstep: {
      component_name: 'mover-app',
      access_token:
        '60d36R3EOxXKI50D+/VBgAKG+XiMPuAFwRF9O71PbZNvaX7UP4VH8/tzloQhAJX4efrznbG0xV1RTXvNJPktAT76GXXLCHusNfyOWhnN',
      default_span_tags: {
        environment: 'production',
      },
    },
    sure: 'Token pMkKx9aPyIoD4YtwlurX1eORBbUSZJhfCzFLQjFqAfG5smp07',
    sureStripe: 'pk_live_OQ9AFCrUOup9fd7LrseHsKiS',
    dc: {
      authorization: DC_PRODUCTION,
    },
  },
  debug: {
    appShell: false,
    appShellRouter: true,
    signInApp: true,
    session: true,
    optimizely: true,
    eventTracker: false,
    graphQl: false,
    moverAppRedux: false,
    utilitiesMarket: false,
    tvInternetApp: false,
    tvInternetCheckout: false,
    shoppingCart: false,
    webpush: false,
    optimizelyModule: true,
    backendOptimizely: true,
    quickSetup: true,
    moveItems: true,
    signUp: true,
    insurance: true,
    moveYourStuff: true,
    packingSupplies: true,
  },
};
