import defaultsDeep from 'lodash/defaultsDeep';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isUndefined from 'lodash/isUndefined';
import isString from 'lodash/isString';
import snakeCase from 'lodash/snakeCase';
import curry from 'lodash/curry';
import get from 'lodash/get';

/**
 * @memberof module:EventTracker
 * @private
 *
 * @param dataset An element's `dataset` property.
 * https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/dataset
 * @return {Object} A nested object representation of `dataset` (DOMStringMap).
 *
 * @example
 * ```html
 * <div
 *   id="myElement"
 *   data-analytics-event="My Event"
 *   data-analytics-properties-value="My Event Value"
 * >
 * ```
 * ```js
 * const { dataset } = document.querySelector('#myElement');
 * const datasetObj = datasetToObject(dataset);
 *
 * console.log(dataset.analyticsEvent, dataset.analyticsPropertiesValue);
 * // --> "My Event", "My Event Value"
 *
 * console.log(datasetObj.analytics.event, datasetObj.analytics.properties.value);
 * // --> "My Event", "My Event Value"
 * ```
 */
export function datasetToObject(dataset) {
  return defaultsDeep(
    {},
    ...Object.keys(dataset).map((key) =>
      key
        .replace(/([A-Z])/g, (_, match) => ` ${match.toLowerCase()}`)
        .split(' ')
        .reduceRight(
          (obj, prop) => ({ [prop]: obj }),
          (() => {
            try {
              return JSON.parse(dataset[key]);
            } catch (e) {
              return dataset[key];
            }
          })()
        )
    )
  );
}

/**
 * Flattens a nested object's keys, camelCasing the resulting keys.
 * E.g. `{ foo: { bar: { baz: 'foo' } } } => { fooBarBaz: 'foo' }`
 *
 * @memberof module:EventTracker
 * @private
 *
 * @param {Object} object
 * @param {String} [prefix] Used in recursion.
 * @return {Object}
 */
export function camelFlattenKeys(object, prefix = '') {
  function toCamel(str) {
    return str.slice(0, 1).toUpperCase() + str.slice(1);
  }

  return Object.keys(object).reduce((result, key) => {
    const camelKey = prefix.length ? toCamel(key) : key;

    return {
      ...result,
      ...(typeof object[key] === 'object'
        ? camelFlattenKeys(object[key], `${prefix}${camelKey}`)
        : { [`${prefix}${camelKey}`]: object[key] }),
    };
  }, {});
}

/**
 * Parses the event's "path" (the elements it bubbled through) for the
 * first element that contains a `data` attribute with analytics metadata.
 *
 * @memberof module:EventTracker
 * @private
 *
 * @param {Event} event
 * @return {Object} The analytics data that was found (or empty object).
 */
export function parseAnalyticsDataFromEvent(event) {
  const path = event.composedPath();
  const element = path.find((el) => el.dataset && el.dataset.analyticsEvent);
  return element ? datasetToObject(element.dataset).analytics : {};
}

export function csvToArray(csv) {
  return csv.split(',').map((str) => str.trim());
}

// TODO: Workaround for Analytics. We need to allow for DOM events
// to reach the analytics event handlers before navigating.
// https://codepen.io/stiggler/pen/PRggZq
export function withTimeout(fn) {
  return (...args) =>
    new Promise((resolve) => setTimeout(() => resolve(fn.apply(fn, args))));
}

/**
 * Returns value at path if none of it's values are `undefined` or `null`;
 * otherwise returns `undefined`.
 *
 * @example
 *
 * ```js
 * selector({}, 'user')
 * // undefined
 *
 * selector({ user: "Foo Bar" }, "user")
 * // Foo Bar
 *
 * selector({ user: "Foo Bar" }, "user", val => val.toUpperCase())
 * // FOO BAR
 *
 * const data = {
 *   user: {
 *     name: "Roger",
 *     email: "r@g.com",
 *     move: { id: "1234", date: Date.now() }
 *   }
 * };
 * selector(data, "user", user => ({
 *   moveId: selector(user, "move.id")
 * }))
 * // { moveId: '1234' }
 * ```
 *
 * @memberof module:EventTracker
 *
 * @param {object} obj Source object
 * @param {string} path Path to property
 * @param {function} modifier Modifier function
 * @returns {*|undefined}
 */
export function selector(obj, path, modifier = (val) => val) {
  return obj ? modifier(get(obj, path)) : undefined;
}

export function isEmpty(obj) {
  return (
    Object.keys(obj).length === 0 ||
    Object.values(obj).every((val) => isUndefined(val) || val === '')
  );
}

export function clean(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    let val = obj[key];

    if (isObject(val) && !isArray(val)) {
      if (isEmpty(val)) {
        val = undefined;
      } else {
        val = clean(val);
      }
    } else if (isString(val) && val === '') {
      val = undefined;
    }

    return {
      ...acc,
      [key]: val,
    };
  }, {});
}

/**
 * Converts a front end event into an event stream event.
 *
 * @memberof module:EventTracker
 *
 * @param {object} event Front end formatted event
 * @returns {object} Event stream formatted event
 */
export function updaterEventFromEvent(event) {
  return clean({
    timestamp: event.timestamp,
    clientSessionId: event.clientSessionId,
    domain: snakeCase(event.domain),
    object: snakeCase(event.object),
    verb: snakeCase(event.verb),
    user: event.user,
    page: event.page,
    details: event.details,
  });
}

export const warn = curry((env, message) => {
  if (['development', 'test'].includes(env.name)) console.warn(message); // eslint-disable-line no-console
});

export function flatten({ delimiter = '.' } = {}) {
  return function recurse(source, path) {
    if (isUndefined(source)) {
      return {};
    }

    if (!isObject(source) || !Object.keys(source).length)
      return { [path]: source };

    return Object.keys(source).reduce((obj, key) => {
      const value = source[key];
      if (Array.isArray(value)) return obj;

      const deeperPath = path ? path + delimiter + key : key;
      return Object.assign(obj, recurse(value, deeperPath));
    }, {});
  };
}
