import {
  VIEW,
  SUBMIT,
  DISPLAY,
  MEASURE,
} from '@updater-core/lib/tracker/constants';
import { ENVIRONMENT } from '@updater-core/lib/environment';
import { startSpan } from '@updater-core/lib/tracing';
import generateQuickSetupEvent from '@updater-core/interactors/shell/tracker/utils/generateQuickSetupEvent';
import withTracing from '@updater-core/interactors/shell/tracker/utils/withTracing';
import { DOMAIN } from '@updater-core/interactors/shell/tracker/constants';
import snakeCase from 'lodash/snakeCase';

const IS_CYPRESS = Boolean(typeof window !== 'undefined' && window.Cypress);

function format(str) {
  return snakeCase(str);
}

// Setup datadog real user monitoring
import(
  /* webpackChunkName: "tracker-datadog-plugin" */ '@updater-core/lib/tracker/plugins/data-dog-rum'
);

export default function trackAll(event, { session }) {
  const trackSpan = startSpan('analytics.track');

  return Promise.all([
    // Validator
    withTracing(
      'analytics.track.validator',
      async () => {
        if (
          ['development', 'sandbox', 'staging', 'updatron'].includes(
            ENVIRONMENT.name
          )
        ) {
          const { validateTrack, BaseEventType, AuthenticatedEventType } =
            await import(
              /* webpackChunkName: "tracker-validator-plugin" */ '@updater-core/lib/tracker/plugins/validator'
            );
          return validateTrack(
            event,
            session.selectors.isAuthenticated(session.getState())
              ? AuthenticatedEventType
              : BaseEventType
          );
        }
        return undefined;
      },
      trackSpan
    )(),

    // Optimizely
    withTracing(
      'analytics.track.optimizely',
      async () => {
        if (
          [
            'development',
            'sandbox',
            'staging',
            'production',
            'demo',
            'test',
            'updatron',
          ].includes(ENVIRONMENT.name)
        ) {
          const [optimizelyPlugin, optimizelyLib] = await Promise.all([
            import(
              /* webpackChunkName: "tracker-optimizely-plugin" */ '@updater-core/lib/tracker/plugins/optimizely'
            ),
            import(
              /* webpackChunkName: "tracker-optimizely-lib" */ '@updater-core/lib/optimizely/lib'
            ),
          ]);

          const uuid = session.selectors.uuid(session.getState());
          if (uuid) {
            return optimizelyPlugin.optimizelyTrack(() => ({
              env: ENVIRONMENT,
              userId: uuid,
              track: optimizelyLib.track,
              transforms: [
                generateQuickSetupEvent(28),
                generateQuickSetupEvent(29),
                generateQuickSetupEvent(30),
                generateQuickSetupEvent(31),
              ],
            }))(event);
          }
        }
        return undefined;
      },
      trackSpan
    )(),

    // Eventstream
    withTracing(
      'analytics.track.eventstream',
      // eslint-disable-next-line @typescript-eslint/no-shadow
      async (event, parentSpan) => {
        const { eventstreamTrack } = await import(
          /* webpackChunkName: "tracker-eventstream-plugin" */ '@updater-core/lib/tracker/plugins/eventstream'
        );
        const eventStreamUrl = ENVIRONMENT.endpoints.eventstream;
        const eventStreamAuthorization =
          ENVIRONMENT.keys.eventstream.authorization;
        return eventstreamTrack(() => ({
          url: eventStreamUrl,
          app: 'Mover',
          authorization: eventStreamAuthorization,
        }))(event, parentSpan);
      },
      trackSpan
    )(event),

    // Segment
    withTracing(
      'analytics.track.segment',
      async () => {
        const [{ segmentTrack }] = await Promise.all([
          import(
            /* webpackChunkName: "tracker-segment-plugin" */
            '@updater-core/lib/tracker/plugins/segment'
          ),
          import(/* webpackChunkName: "tracker-segment-lib" */ './analytics'),
        ]);
        segmentTrack()(event);
      },
      trackSpan
    )(),

    // Sentry
    withTracing(
      'analytics.track.sentry',
      async () => {
        const [{ sentryTrack }, { buildSentry }] = await Promise.all([
          import(
            /* webpackChunkName: "tracker-sentry-plugin" */ '@updater-core/lib/tracker/plugins/sentry'
          ),
          import(/* webpackChunkName: "tracker-sentry-build" */ './sentry'),
        ]);
        buildSentry();
        sentryTrack()(event);
      },
      trackSpan
    )(),

    // Difference
    withTracing(
      'analytics.track.difference',
      async () => {
        const [{ differenceTrack }, { tracker }] = await Promise.all([
          import(
            /* webpackChunkName: "tracker-difference-plugin" */ '@updater-core/lib/tracker/plugins/difference'
          ),
          import(
            /* webpackChunkName: "tracker-difference-tracker" */ '@updater-core/lib/tracker/tracker'
          ),
        ]);
        const lastPage =
          localStorage.getItem('upd_signup.lastPage') || 'password';
        return differenceTrack({
          track: tracker.track,
          combinations: [
            // At this point, we don’t know what page the user will see first
            // In analysis, look for the smallest of the wait times
            {
              event: (a, b) => ({
                domain: DOMAIN,
                object: 'product_intro_wait_time',
                verb: MEASURE,
                details: {
                  waitTime: b.timestamp - a.timestamp,
                },
              }),
              start: {
                domain: 'signup',
                object: lastPage,
                verb: SUBMIT,
              },
              end: {
                domain: DOMAIN,
                object: 'product_intro',
                verb: DISPLAY,
              },
            },
            {
              event: (a, b) => ({
                domain: DOMAIN,
                object: 'home_services_landing_wait_time',
                verb: MEASURE,
                details: {
                  waitTime: b.timestamp - a.timestamp,
                },
              }),
              start: {
                domain: 'signup',
                object: lastPage,
                verb: SUBMIT,
              },
              end: {
                domain: 'home_services',
                object: 'landing',
                verb: VIEW,
              },
            },
            {
              event: (a, b) => ({
                domain: DOMAIN,
                object: 'home_services_internet_speed_wait_time',
                verb: MEASURE,
                details: {
                  waitTime: b.timestamp - a.timestamp,
                },
              }),
              start: {
                domain: 'signup',
                object: lastPage,
                verb: SUBMIT,
              },
              end: {
                domain: 'home_services',
                object: 'internet_speed',
                verb: VIEW,
              },
            },
            {
              event: (a, b) => ({
                domain: 'home_services',
                object: 'offers_wait_time',
                verb: MEASURE,
                details: {
                  waitTime: b.timestamp - a.timestamp,
                },
              }),
              start: {
                domain: 'home_services',
                object: 'phone_needs',
                verb: SUBMIT,
              },
              end: {
                domain: 'home_services',
                object: 'offers',
                verb: DISPLAY,
              },
            },
            {
              event: (a, b) => ({
                domain: 'home_services',
                object: 'offers_load_time',
                verb: MEASURE,
                details: {
                  waitTime: b.timestamp - a.timestamp,
                },
              }),
              start: {
                domain: 'home_services',
                object: 'browse_offers',
                verb: VIEW,
              },
              end: {
                domain: 'home_services',
                object: 'offers',
                verb: DISPLAY,
              },
            },
            {
              event: (a, b) => ({
                domain: 'secure_your_insurance',
                object: 'leasing_desk_widget_wait_time',
                verb: MEASURE,
                details: {
                  waitTime: b.timestamp - a.timestamp,
                },
              }),
              start: {
                domain: 'secure_your_insurance',
                object: 'leasing_desk_form',
                verb: VIEW,
              },
              end: {
                domain: 'secure_your_insurance',
                object: 'leasing_desk_widget',
                verb: DISPLAY,
              },
            },
          ],
        })(event);
      },
      trackSpan
    )(),

    // Logger
    withTracing(
      'analytics.track.logger',
      async () => {
        const { loggerTrack } = await import(
          /* webpackChunkName: "tracker-logger-plugin" */ '@updater-core/lib/tracker/plugins/logger'
        );

        if (ENVIRONMENT?.debug?.eventTracker) {
          return loggerTrack()(event);
        }
        return undefined;
      },
      trackSpan
    )(),

    // Cypress
    IS_CYPRESS &&
      withTracing(
        'analytics.track.spy',
        async () => {
          if (typeof window !== 'undefined' && window.Cypress) {
            /* eslint-disable no-underscore-dangle */
            window.__updTrackerSpies = window.__updTrackerSpies || {};
            Object.keys(window.__updTrackerSpies).forEach((key) => {
              const [spyDomain, spyObject, spyVerb] = key.split('.');

              const domain = format(event.domain || '');
              const object = format(event.object || '');
              const verb = format(event.verb || '');

              if (
                (spyDomain === domain || spyDomain === '*') &&
                (spyObject === object || spyObject === '*') &&
                (spyVerb === verb || spyVerb === '*')
              ) {
                window.__updTrackerSpies[key](event);
              }
            });
            /* eslint-enable */
          }
        },
        trackSpan
      )(),
  ]).then(() => trackSpan.finish());
}
