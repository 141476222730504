/**
 * @module logger
 * @description A logger utility that supports 3 usecases:
 * * making a logger for multipe files with the same configurations
 * * making a logger for a single file
 * * using a logger as middleware in a store
 *
 * Functions:
 */

import { IS_DEV, IS_TEST } from '@updater-core/lib/environment';

const DEV_MODE = IS_DEV || IS_TEST;

/**
 * @memberof module:logger
 * 
 * @description
 * 
 * create a function for filtering state to avoid logging sensative information
 * 
```js
import { filterStateForLogging } from '@updater-core/lib/logger';

const filterState = filterStateForLogging(['password', 'client', 'access-token']);
filterState({ username: 'foo', password: 1, client: 'bar', access-token: '123e'});
// {username: 'foo', password: 'REDACTED', client: 'REDACTED', access-token: 'REDACTED'}

```
 */
export function filterStateForLogging(keysToFilter = []) {
  return (state) => {
    function removeKeys(obj) {
      return obj && typeof obj === 'object' && !Array.isArray(obj)
        ? Object.entries(obj).reduce(
            (acc, [key, value]) => ({
              ...acc,
              // eslint-disable-next-line @getify/proper-ternary/nested
              [key]: keysToFilter.includes(key)
                ? 'REDACTED'
                : removeKeys(value),
            }),
            {}
          )
        : obj;
    }

    // just in case anything goes wrong above, dont bring down the app :)
    let ret = state;
    try {
      ret = removeKeys(state);
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error(ex);
    }
    return ret;
  };
}

/**
 * @memberof module:logger
 * 
 * @description
Create a custom createLogger that lets you create derivative loggers.

All logs that are logged in non-development environments
are automatically subject to filtering of sensitive user info 
since non-dev environment logs are integrated with third-party reporters
which could reveal this information unexepctedly. 

Usage in a local logger file:

```js
import { createLoggerFunc } from '@updater-core/lib/logger';

// export a createLogger you can import at the top of files
// within a module with the debug flag, prefixes, and color specified.
// and accept additional prefixes
export const createLogger = (...args) => {
  return createLoggerFunc(
    ENVIRONMENT.debug?.signInApp,
    '[DEBUG][SIGN-IN]',
    'color: #6B5B95'
  )(...args);
};

// export a logger directly that does not need additional prefixes
export const logger = createLogger();
```

Usage in other files 
```js
import { createLogger } from './logger'; // where you export `createLogger`

const { log } = createLogger('[STORE]');
```
 */
export function createLoggerFunc(debug, prefix, color): Function {
  return (...otherPrefixes) =>
    Object.create(
      {},
      {
        log: {
          get: () => {
            // if debug is enabled and the environment is dev, return bound console
            // so logs are passed-through as-is.
            if (debug && DEV_MODE) {
              // eslint-disable-next-line no-console
              return console.log.bind(
                console,
                ['%c', prefix, ...otherPrefixes].join(''),
                color
              );
            }
            // if debug is enabled and the environment is NOT DEV, return new function
            // so logs can be filtered
            if (debug && !DEV_MODE) {
              const filterState = filterStateForLogging([
                'password',
                'client',
                'access-token',
                'security_token',
                'number',
                'creditCard',
                'credit_card',
                'creditcard',
                'ssn',
                'socialSecurityNumber',
                'social_security_number',
              ]);
              return (...args) => {
                const filteredLogs = args.map(filterState);
                // eslint-disable-next-line no-console
                console.log.call(
                  console,
                  [prefix, ...otherPrefixes].join(''),
                  ...filteredLogs
                );
              };
            }
            // if debug is not enabled, surpress all logs
            return () => {};
          },
        },
      }
    );
}

/**
 * @memberof module:logger
 * 
 * @description
 * 
 * create a logger for a file locally
 * 
```js
import { createLogger } from '@updater-core/lib/logger';
import { ENVIRONMENT } from '@updater-core/lib/environment';

const debugFlag = ENVIRONMENT?.debug?.youApp
const { log } = createLogger(debugFlag, '[APP_NAME]', 'color: #abac12');
```
 */
export function createLogger(debug, prefix, color): { log: Function } {
  const logger = createLoggerFunc(debug, prefix, color)();
  return logger;
}

/**
 * @memberof module:logger
 * 
 * @description
 * 
 * create a middleware which uses your logger for a store
 * 
```js
import { loggerMiddleware, createLogger } from '@updater-core/lib/logger';
import { ENVIRONMENT } from '@updater-core/lib/environment';
import { createStore } from '@updater-core/lib/store';

const debugFlag = ENVIRONMENT?.debug?.youApp
// Make a logger with your prefix
const logger = createLogger(debugFlag, '[APP_NAME][STORE][ACTION]', 'color: #abac12');
// Pass in the custom logger's `log` function into the loggerMiddleware
const store = createStore({}, [loggerMiddleware(logger.log)]);

```
 */
export function loggerMiddleware(log): Function {
  return (store, action = '') =>
    (next) =>
    (payload) => {
      const prevState = store.getState();
      next(payload);
      const nextState = store.getState();
      log(action, {
        prevState,
        payload,
        nextState,
      });
    };
}

/**
 * @memberof module:logger
 * 
 * @description
 * 
 * get a clean stack trace to include with logs for debugging
 * 
```js
import { createLogger, getCleanStack } from '@updater-core/lib/logger`

const logger = createLogger(debugFlag, '[APP_NAME][PREFIX]', 'color: #abac12');

logger.log('debug', getCleanStack());

```
 */
export function getCleanStack(): string {
  let stack = '';
  try {
    // slice at 2 to avoid "Error" and "getCleanStack" in the trace
    stack = Error().stack.split(/\n/).slice(2).join('\n');
    // eslint-disable-next-line no-empty
  } catch {}
  return stack;
}

/*
Above jsdoc can be compiled to a doc with the following command:
updoc --files local_modules/@updater-core/lib/logger/index.js --outputFile local_modules/@updater-core/lib/logger/README.md 
*/
