import { Environment } from '../types';
import { DC_STAGING } from './keys';

export const dev: Environment = {
  name: 'development',
  cookieRootDomain: 'dev.updater.com',
  endpoints: {
    api: {
      url: '//api.staging.updater.com',
    },
    cloudinary: 'https://res.cloudinary.com/updater-staging/image/upload',
    optimizely:
      'https://cdn.optimizely.com/datafiles/PV8bgAg9tPhvowmGw4zz4X.json',
    eventstream: 'http://eventstream-api.dev.updater.com/xyz',
    sure: {
      protections:
        'https://api.trysureapp.com/api/partner/v1.1/protections/renters',
      partner: 'https://api.trysureapp.com/api/partner/v1',
    },
    leasingDeskWidget: 'https://ri-widget-uat-demo.realpage.com/widget',
    verticalOne: 'https://api-staging.verticalone.com/v1',
    dollyUi: 'https://book-staging.dolly.com/',
    dc: 'https://api.staging.bridgevine.io/v6',
    tokenService: {
      url: 'https://v8vy0byloh.execute-api.us-east-1.amazonaws.com',
    },
    consumerAppUrl: 'https://consumer-app.staging.updater.com',
    bucketingServiceUrl: '//bucketing-service-staging.updatron.workers.dev',
  },
  keys: {
    firebase: {
      apiKey: 'AIzaSyCuCxM5wfGBc90RHxCQUf2SrFLFknt1z3w',
      authDomain: 'updater-web-push-staging.firebaseapp.com',
      databaseURL: 'https://updater-web-push-staging.firebaseio.com',
      projectId: 'updater-web-push-staging',
      storageBucket: 'updater-web-push-staging.appspot.com',
      messagingSenderId: '1032981526758',
      appId: '1:1032981526758:web:636f0adbdec98c5bd46327',
      measurementId: 'G-GHX52JRFNH',
    },
    // firebase cloud messaging
    firebase_cm: {
      vapidKey:
        'BOkvsPk3yeoJhfJBWOFYdZwryzRE-I3oOEBDEHMmH26dgpQtJDQxpKfjQzgs2MIfcySn5jLWDQFNvdSYeFSZ_ZQ',
    },
    smartystreets: {
      authToken: '4537425833772681843',
    },
    eventstream: {
      authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55IjoiVXBkYXRlciIsImVtYWlsIjoiYWRtaW5AdXBkYXRlci5jb20iLCJuYW1lIjoiVXBkYXRlciBBZG1pbiIsImlhdCI6MTUwNDEwMTYzMiwiaXNzIjoiVXBkYXRlciJ9.vWh5iZY_npjI5o9vDCHDvAygbuhpn5fL64uGnCaeF_Q',
    },
    selectquote: {
      authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55IjoiVXBkYXRlciIsImVtYWlsIjoiYWRtaW5AdXBkYXRlci5jb20iLCJuYW1lIjoiVXBkYXRlciBBZG1pbiIsImlhdCI6MTUwNDEwMTYzMiwiaXNzIjoiVXBkYXRlciJ9.vWh5iZY_npjI5o9vDCHDvAygbuhpn5fL64uGnCaeF_Q',
    },
    stripe: 'pk_test_KZhy8xHQcPTYedpuiPekDh8w',
    segment: 'f2ZJEsBwVimRzNsLmTl4ys4G10T5Ntsu',
    sentry: 'https://1b861731eeec46199ff1d42514627cbc@sentry.io/53228',
    lightstep: {
      component_name: 'mover-app',
      access_token:
        'w1B1TEmt+vx4zmJZ+l4OiAI/q01IFXOlmWigjzHX3a/GvJTIzk6PIiV6E45TdSacZm7S9VnxxrE2JEqj0F2PJ5noBojbVxPpqJVlRRzQ',
      default_span_tags: {
        environment: 'dev',
      },
      collector_host: 'localhost',
      collector_port: 8360,
      collector_encryption: 'none',
    },
    sure: 'Token pRLyZdYX3EsuNm6en7rBhFDokItCqfiMgb8x9SQAcwazTpHG0',
    sureStripe: 'pk_test_D2bzH96t4WolPemRrrmHXjoT',
    dc: {
      authorization: DC_STAGING,
    },
  },
  debug: {
    appShell: false,
    appShellRouter: false,
    signInApp: false,
    session: false,
    optimizely: false,
    eventTracker: true,
    graphQl: false,
    moverAppRedux: false,
    utilitiesMarket: false,
    tvInternetApp: true,
    tvInternetCheckout: true,
    shoppingCart: true,
    webpush: false,
    headerApp: false,
    footerComponent: false,
    optimizelyModule: false,
    utilsConfirmation: true,
    utilsDashboard: true,
    forwardMail: false,
    quickSetup: false,
    verify: false,
    routerPersistance: false,
    creditCard: true,
    promotions: false,
    backendOptimizely: true,
    tracker: false,
    moveItems: false,
    insurance: false,
    signUp: false,
    moveYourStuff: false,
    packingSupplies: false,
  },
  integrations: {
    disableRaven: true,
  },
};
