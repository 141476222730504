import { v4 as uuidv4 } from 'uuid';
import deleteItemFromStorage from '@updater-core/interactors/shell/tracker/utils/deleteItemFromStorage';
import getItemFromStorage from '@updater-core/interactors/shell/tracker/utils/getItemFromStorage';

// The `clientSessionId` must be read from `localStorage` so that we can
// connect events from `sign-up-app`.
export default function createClientSessionId () {
  const CLIENT_SESSION_ID_KEY = 'upd_clientSessionId';
  const currentId = getItemFromStorage(CLIENT_SESSION_ID_KEY);

  if (currentId) {
    deleteItemFromStorage(CLIENT_SESSION_ID_KEY);
    return currentId;
  }
  return uuidv4();
}
