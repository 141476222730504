/* eslint-disable no-underscore-dangle */
import { environments } from '../environments';

// Storage interface for safe access
const storage = {
  getItem: (item) => {
    try {
      return localStorage.getItem(item);
    } catch {
      return null;
    }
  },
  removeItem: (item) => {
    try {
      return localStorage.removeItem(item);
    } catch {
      return null;
    }
  },
};

function getDebugFromQueryParams() {
  if (typeof window === 'undefined') {
    return undefined;
  }

  const query = decodeURIComponent(window.location.search.substr(1));
  if (!query) {
    return undefined;
  }
  const params = query.split('&').reduce((acc, keyValuePair) => {
    const split = keyValuePair.split('=');
    const [key, value] = split;
    return { ...acc, ...{ [key]: value } };
  }, {});

  return params.debug;
}

export function getEnabledDebugs() {
  const debug = getDebugFromQueryParams() || storage.getItem('upd_debug');
  if (debug && debug !== 'clear' && debug !== 'undefined') {
    const props = debug.split(',');
    const enabled = props.reduce(
      (acc, prop) => ({
        ...acc,
        [prop]: true,
      }),
      {}
    );
    if (enabled) {
      localStorage.setItem('upd_debug', Object.keys(enabled));
    }
    return enabled;
  }
  storage.removeItem('upd_debug');
  return undefined;
}

if (typeof window !== 'undefined') {
  window.__updDebugOptions = Object.keys(environments.dev.debug);
  window.__updEnableDebug = (options = window.__updDebugOptions) => {
    localStorage.setItem('upd_debug', options);
    window.location.reload();
  };
  window.__updClearDebug = () => {
    localStorage.removeItem('upd_debug');
    window.location.reload();
  };
}
