import generateEventNameWithPrefix from '@updater-core/interactors/shell/tracker/utils/generateEventNameWithPrefix';

export default function generateQuickSetupEvent (testNumber) {
  return  generateEventNameWithPrefix({
    prefix: `t${testNumber}.`,
    events: [
      // Quick Setup Events
      'quick_setup.landing.viewed',
      'quick_setup.landing_card.clicked',
      'quick_setup.landing_cta.clicked',
      'quick_setup.completed_flow.viewed',
      'quick_setup.completed_flow.submitted',
      'quick_setup.skip_link.clicked',
      // Insurance Events
      'insurance.landing.viewed',
      'insurance.complete.viewed',
      'insurance.complete.submitted',
      'insurance.already_done.clicked',
      // TV/I Events
      'home_services.landing.viewed',
      'home_services.skip_button.clicked',
      'home_services.new_service.clicked',
      'home_services.transfer_service.clicked',
      'home_services.landing.submitted',
      'home_services.browse_offers.viewed',
      'home_services.schedule_installation.viewed',
      'home_services.transfer_call.viewed',
      'home_services.next_step.clicked',
      // Utilities Events
      'utilities.landing.viewed',
      'utilities.visit_website.clicked',
      'utilities.home.clicked',
      'utilities.phone.clicked',
      'utilities.already_done.clicked',
      'utilities.complete.clicked',
      // FYM Events
      'forward_mail.form.submitted',
      'forward_mail.review.viewed', // equivalent to the last page of other steps
      'forward_mail.from_address.viewed', // equivalent to the landing page of other steps
      'forward_mail.already_done.clicked',
      'forward_mail.movers.viewed',
      // Verify events
      'verify.verify.submitted',
    ],
    condition: ({ page }) => page.url?.includes(`/quick-setup/${testNumber}`),
  });
}