/* eslint-disable import/no-mutable-exports */
import {
  Tracer,
  Span,
} from 'opentracing';
import lightstep from 'lightstep-tracer';

export const tracer = new Tracer();
export const lightstepTracer = new lightstep.Tracer({
  silent: true,
  disable_reporting_loop: true,
  disable_report_on_exit: true,
  disabled: true,
});

export function startSpan(...args) {
  return tracer.startSpan(...args);
}

export function tagError(span, e) {
  try {
    span.setTag('error', true);
    span.setTag('error.object', JSON.stringify({ ...e }));
    span.setTag('error.message', e?.message || e?.reason);
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.error('Error while tagging span');
    // eslint-disable-next-line no-console
    console.error(ex);
  }

  return span;
}

export function isSpan(span) {
  return (
    span instanceof Span ||
    // eslint-disable-next-line no-underscore-dangle
    (lightstepTracer && span instanceof lightstepTracer?._opentracing?.Span)
  );
}

export function flushSpans() {
  return new Promise((resolve) => {
    try {
      lightstepTracer.flush(resolve);
    } catch (e) {
      // if something goes wrong that's ok
      resolve();
    }
  });
}

export function traceActions({ name, ...actionsToTrace }) {
  const actionNames = Object.keys(actionsToTrace);
  const actions = actionNames.reduce(
    (newActions, actionName) => ({
      ...newActions,
      [actionName]: function traceAction(...args) {
        const parentSpanIndex = args.findIndex(isSpan);
        const parentSpan = args[parentSpanIndex];
        const span = startSpan(`${name}.action`, {
          childOf: parentSpan,
        });
        span.setTag('action.name', actionName);

        if (parentSpan) {
          // swap in the new parent span
          // eslint-disable-next-line no-param-reassign
          args[parentSpanIndex] = span;
        } else {
          // otherwise tack on the span to the end for use by the interactor
          // eslint-disable-next-line no-param-reassign
          args = [...args, span];
        }

        let returnVal;
        try {
          returnVal = actionsToTrace[actionName](...args);
        } catch (error) {
          tagError(span, error);
          span.finish();
          throw error;
        }

        if (
          typeof returnVal?.then === 'function' &&
          typeof returnVal?.catch === 'function'
        ) {
          span.setTag('action.async', true);
          returnVal
            .then((result) => {
              span.setTag('action.result', result);
              span.finish();
              return result;
            })
            .catch((error) => {
              tagError(span, error);
              span.finish();
              throw error;
            });
        } else {
          span.setTag('action.async', false);
          span.setTag('action.result', returnVal);
          span.finish();
        }

        return returnVal;
      },
    }),
    {}
  );
  return actions;
}
