import { Store } from './types.d';

export * from './storageMiddleware';

export function createStore<T = any>(
  initialState: T,
  middlewares = []
): Store<T> {
  let state: T = initialState;
  let callbacks = [];

  function setState(payload: Partial<T>, middlewareArgs) {
    let newPayload = payload;

    function next(nextPayload: Partial<T>) {
      newPayload = nextPayload;
      state = {
        ...state,
        ...newPayload,
      };
    }

    next(payload);
    middlewares.forEach((middleware) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      middleware(store, middlewareArgs)(next)(newPayload);
    });

    callbacks.forEach((callback) => {
      try {
        callback(state);
      } catch (ex) {
        // Rethrow to be caught by something in the ether (Raven)
        // but not to prevent the subsequent callbacks
        setTimeout(() => {
          throw ex;
        });
      }
    });
  }

  function getState() {
    return state;
  }

  function clearState() {
    state = null;
    callbacks.forEach((val) => val(state));
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function subscribe(fn: (state: T) => void) {
    callbacks.push(fn);

    return () => {
      callbacks = callbacks.filter((val) => val !== fn);
    };
  }

  const store = {
    setState,
    getState,
    clearState,
    subscribe,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    connect: (fn: (state: T, disconnect: Function) => void) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const initialState = getState();
      let dontSubscribe = false;
      // eslint-disable-next-line func-style
      let disconnect = () => {
        dontSubscribe = true;
      };
      fn(initialState, disconnect);

      if (!dontSubscribe) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        disconnect = subscribe((state) => fn(state, disconnect));
      }
      return disconnect;
    },
  };

  return store;
}
