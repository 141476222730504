import gql from 'graphql-tag';
import { fetchGraphQLQuery } from '@updater-core/lib/graphql';
import { ENVIRONMENT } from '@updater-core/lib/environment';
import { track, ERROR } from '@updater-core/lib/tracker';
import { RemoteStorageGateway } from './interface';

const setQuery = gql`
  mutation remoteGatewaySet($input: MoveAddKeyInput!) {
    moveAddKey(input: $input) {
      key
      value
    }
  }
`;
const getQuery = gql`
  query remoteGatewayGet($input: MoveFindKeyInput!) {
    moveFindKey(input: $input) {
      key
      value
    }
  }
`;

function trackError(object, error, details) {
  return track({
    domain: 'mover',
    verb: ERROR,
    object,
    details: {
      stack: error?.stack,
      message: error?.message,
      reason: error?.reason,
      ...error,
      ...details,
    },
  });
}

export function getValue({ uuid, headers, key }) {
  return fetchGraphQLQuery({
    headers,
    query: getQuery,
    variables: {
      input: {
        key,
        uuid,
      },
    },
    url: `${ENVIRONMENT.endpoints.api.url}/graphql`,
  }).then((res) => {
    if (res?.error) {
      trackError('remote_storage_get_gql', res?.error, { key, uuid });
    }
    return res?.data?.moveFindKey;
  });
}

export function setValue({ value, uuid, headers, key }) {
  return fetchGraphQLQuery({
    headers,
    query: setQuery,
    variables: {
      input: {
        key,
        value,
        uuid,
      },
    },
    url: `${ENVIRONMENT.endpoints.api.url}/graphql`,
  }).then((res) => {
    if (res?.error) {
      trackError('remote_storage_set_gql', res?.error, { key, uuid, value });
    }
    return res?.data?.moveAddKey;
  });
}

export function remove({ uuid, headers, key }) {
  return setValue({
    key,
    uuid,
    headers,
    value: JSON.stringify({}),
  }).catch((e) => {
    trackError('remote_storage_remove', e, { id: key });
  });
}

export function createGateway(
  uuid: string,
  headers: string
): RemoteStorageGateway {
  return {
    get: (id) =>
      getValue({
        key: id,
        uuid,
        headers,
      }).catch((e) => {
        trackError('remote_storage_get', e, { id });
        return null;
      }),
    set: (id, value) =>
      setValue({
        key: id,
        uuid,
        headers,
        value,
      }).catch((e) => {
        trackError('remote_storage_set', e, { id, value });
      }),
    getValue: ({ key }) =>
      getValue({
        key,
        uuid,
        headers,
      }).catch((e) => {
        trackError('remote_storage_get', e, { key });
        return null;
      }),
    setValue: ({ key, value }) =>
      setValue({
        key,
        uuid,
        headers,
        value,
      }).catch((e) => {
        trackError('remote_storage_set', e, { key, value });
      }),
    getJSON: (id) =>
      getValue({
        key: id,
        uuid,
        headers,
      })
        .then((data) => (data?.value ? JSON.parse(data.value) : null))
        .catch((e) => {
          trackError('remote_storage_get_json', e, { id });
          return null;
        }),
    setJSON: (id, json) =>
      setValue({
        key: id,
        uuid,
        headers,
        value: JSON.stringify(json),
      }).catch((e) => {
        trackError('remote_storage_set_json', e, { id, json });
      }),
    remove: (id) =>
      setValue({
        key: id,
        uuid,
        headers,
        value: JSON.stringify({}),
      }).catch((e) => {
        trackError('remote_storage_remove', e, { id });
      }),
  };
}
