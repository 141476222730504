import { base } from './base';
import { dev } from './dev';
import { demo } from './demo';
import { staging } from './staging';
import { production } from './production';
import { sandbox } from './sandbox';
import { test } from './test';
import { docker } from './docker';
import { updatron } from './updatron';

// Exported properties need to match the environment subdomain
// Exceptions are
//    - `base` since it is the base environment and has no subdomain in the hostname
//    - `production` since it has no environment in the hostname
//    - any extension environment specified via `extend-env` querystring (e.g. e2e)
export const environments = {
  base,
  dev,
  demo,
  staging,
  production,
  sandbox,
  test,
  docker,
  updatron,
};
