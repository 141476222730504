import { Environment } from '../types';

export const base: Environment = {
  endpoints: {
    api: {
      version: 'v2',
    },
    facebook: '//connect.facebook.net/en_US/sdk.js',
    salesforce: 'https://sandbox-updater2.cs17.force.com/uws/services/apexrest',
  },
  keys: {
    facebook: '162499060440996',
    googleMap: 'AIzaSyDjcyoTgn2RVI5A-rGziegvDyJo3ai0gZY',
  },
  debug: {},
  integrations: {},
};
