export default function getCookie(cookieName) {
  if (typeof document === 'undefined') return undefined;
  const cookie = document.cookie
    .split(';')
    .map((cookieParts) => {
      const [name, value] = cookieParts.trim().split('=');
      return { name, value };
    })
    .find((cookieParts) => cookieParts.name === cookieName);
  if (cookie) {
    return cookie.value;
  }
  return undefined;
}
