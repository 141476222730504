/* eslint-disable camelcase */
import { Interactor } from '@updater-core/lib/store/types.d';
import { Declined } from '@updater-core/interactors/move-items/types.d';
import { AddressHomeSize } from '@updater-core/types/generated/globalTypes';
import { QuizResults } from '@updater-apps/tv-internet-v2/pages/shop-buy-flow/services/quiz-schema';

export type RawSessionPayLoadCurrentMove = {
  journey: any;
  from_address: SessionAddress;
  to_address: SessionAddress;
  signed_up_at: string;
  date: string;
  id: string;
  direction: MoveDirections;
  directionType: string;
  is_verified: boolean;
  created_at: string;
  metadata: any;
  items: any[];
  declined_item_types: {};
  claimed_invite_id: number;
};

export type RawSessionPayLoadUser = {
  id: string;
  current_move: RawSessionPayLoadCurrentMove;
  signed_up_at: string;
  email: string | null;
  uuid: string;
  current_move_id: string;
  test: boolean;
  site_entity: {
    id: string;
    name: string;
    subdomain: string;
    config: {
      features: {
        utilities: UtilityFeature;
        resident_repository: any;
        resident_onboarding?: boolean;
      };
    };
    parent: {
      config: {
        features: {
          resident_repository: any;
          resident_onboarding?: boolean;
        };
      };
    };
  };
  app: string;
  client_name: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  names: string[];
  home_phone: string | null;
  cell_phone: string | null;
  metadata: {};
  moves: MovesReferenceObject;
};

export type RawSessionPayLoadSiteConfigChannel = {
  name: string;
  division_name: string;
  client_name: string;
};

export type RawSessionPayLoadItemDefinition = {
  map: {};
  code: string;
  icon?: string;
  long_description?: string;
  short_description?: string;
  page?: {
    required?: boolean;
  };
};

export type RawSessionPayLoadSiteConfig = {
  entities: Entity[];
  item_definitions: RawSessionPayLoadItemDefinition[];
  channel: RawSessionPayLoadSiteConfigChannel;
  item_definition_phases: ItemDefinitionPhase[];
  privacy_policies: any;
  features: {
    [feature_name: string]: boolean;
  };
  dashboard: {
    banner_html: string;
  };
};

export type RawSessionPayLoad = {
  user: RawSessionPayLoadUser;
  site_config: RawSessionPayLoadSiteConfig;
  uid: string;
  code: string;
  reset: boolean;
  forgot: boolean;
  client: string;
};

export type Entity = {
  id: string;
  code: string;
  name: string;
  kind: string;
  is_site: boolean;
  automated: boolean;
  channel: string;
  subscription_type: string;
  is_company: boolean;
  branding_strategy: string;
  logo_id: string;
  status: string;
  social: {
    facebook_url: string | null;
    twitter_url: string | null;
    linkedin_url: string | null;
    website: string | null;
  };
  display_name: string | null;
  site_url: string;
  professionals_site_url: string;
  company_site_url: string;
  is_division: boolean;
  show_branding: true;
  parent_id: string;
  type: string;
  config: {};
};

export type MoveDirections = 'move_in' | 'move_out' | 'transfer' | 'unknown';

export type ItemDefinitionPhase = {
  id: string;
  type: 'item_definition_phases';
};

export type SessionRequestHeader = {
  uid: string;
  client: string;
  'access-token': string;
  app: string;
};

export type MoveReference = {
  id: string;
  type: 'moves';
};

export type MovesReferenceObject = {
  data: MoveReference[];
};
export type Session = QuizResults & {
  eventStreamAttributes?: any;
  uuid?: string;
  userId?: string;
  test?: any;
  signUpDateTime?: string;
  siteEntityName?: any;
  siteEntitySubdomain?: any;
  rentersInsurance?: any;
  items?: any;
  itemDefinitions?: any;
  currentMove?: any;
  currentMoveId?: any;
  isAuthenticated?: boolean;
  isUnauthenticated?: boolean;
  isLockedOut?: boolean;
  hasResetPassword?: boolean;
  hasForgotPassword?: boolean;
  requestHeaders?: SessionRequestHeader;
  channelName?: any;
  isPropertyManagement?: boolean;
  channelClientName?: string;
  channelDivisionName?: string;
  isHomeOwner?: boolean;
  userFirstName?: string;
  userMiddleName?: string;
  userLastName?: string;
  userFullName?: string;
  userNames?: any;
  userPhoneNumber?: string;
  userEmail?: string;
  userMetadata?: any;
  currentMoveDate?: any;
  currentMoveDirection?: MoveDirections;
  currentMoveDirectionType?: any;
  // From Adress
  currentMoveFromAddress?: Partial<SessionAddress>;
  currentMoveFromAddressHomeSize?: HomeSizeValue; // TODO map home size enum here
  // To Address
  currentMoveToAddress?: Partial<SessionAddress>;
  currentMoveToAddressHomeSize?: string; // TODO map home size enum here
  currentMoveVerified?: any;
  fromHomeSize?: HomeSizeValue;
  fromStreet?: any;
  fromUnit?: any;
  fromCity?: any;
  fromState?: any;
  fromZip?: any;
  fromClassification?: any;
  fromMetadata?: any;
  toHomeSize?: HomeSizeValue;
  toStreet?: string;
  toUnit?: string;
  toCity?: string;
  toState?: string;
  toZip?: string;
  toClassification?: string | unknown;
  toMetadata?: any;
  toOwnership?: string;
  moves?: any;
  privacyPolicies?: any;
  itemDefinitionPhases?: any;
  declinedItems?: Declined;
  dashboardBannerHtml?: any;
  entities?: Entity[];
  company?: {
    code?: string;
    name?: string;
  };
  journey?: string;
  siteEntityId?: string;
  residentRepositoryFlag?: boolean;
  residentOnboardingFlag?: boolean;
  utilityFeatures: UtilityFeature;
  divisionId: string | null;
};

export type HomeSizeValue = AddressHomeSize;

export { AddressHomeSize };

type ToSelectors<Type> = {
  [Property in keyof Type]: (state: RawSessionPayLoad) => Type[Property];
};

export interface SessionInteractor extends Interactor<any> {
  initialized?: (fn?: (state: any) => void) => () => void;
  actions: {
    fetchSession?: () => Promise<any>;
    createSession?: () => RawSessionPayLoad;
    setSession?: () => void;
    clearSession?: () => void;
    setResetPassword?: () => RawSessionPayLoad;
    setForgotPassword?: () => RawSessionPayLoad;
    setUUID?: () => RawSessionPayLoad;
    setEmail?: () => RawSessionPayLoad;
    setVerified?: (verified: boolean) => void;
    log?: () => any;
  };
  selectors: ToSelectors<Session>;
}

export type SessionAddress = {
  id: number;
  base_street: string;
  street: string;
  apt?: string;
  city: string;
  state: string;
  zip: string;
  add_on_zip?: string;
  classification?: string;
  verified?: boolean;
  status?: string;
  ownership?: string;
  components: any;
  analytics: any;
  metadata: any;
};

export type ProofFormat = 'document' | 'account_number';
export interface UtilityFeature {
  electric: boolean;
  electric_proof_format: ProofFormat;
  gas: boolean;
  gas_proof_format: ProofFormat;
  water: boolean;
  water_proof_format: ProofFormat;
}

export type EventStreamAttributeConfiguration = {
  channel: string;
  entityIds: string;
  division_id: string | null;
  user: {
    email: string;
    uuid: string;
    moveId: string;
    test: boolean;
    move: {
      id: string;
      ownership: string;
      direction: MoveDirections;
      days_from_signup_to_move_date: number;
      days_to_move_date: number;
      signed_up_after_move_date: boolean;
      today_is_after_move_date: boolean;
      to_home_size: HomeSizeValue;
      from_home_size: HomeSizeValue;
      journey: any;
      to_status: string;
      to_state: string;
      created_at: string | number | undefined;
      available_item_codes: string;
      holdback: boolean;
    };
    renters_insurance: {
      preferred_provider: string;
    };
  };
};

export type ActiveSessionState = {
  initialized: boolean;
};

export type SessionState = RawSessionPayLoad & ActiveSessionState;
