/* eslint-disable no-console */
import { getAttributes, track, UPDATE, TRACK } from '@updater-core/lib/tracker';
import {
  getVariation as _getVariation,
  activate as _activate,
  isFeatureEnabled as _isFeatureEnabled,
  getFeatureVariables as _getFeatureVariables,
  getFeatureTest,
  generateEventAttributes,
  reinit,
  hasBeenActivated as _hasBeenActivated,
} from '@updater-core/lib/optimizely/lib';
import { ENVIRONMENT } from '@updater-core/lib/environment';
import { createLogger } from './logger';

const { log } = createLogger();

export const hasBeenActivated = _hasBeenActivated(ENVIRONMENT);

export * from './user-profile-service';
export * from './logger';

function cypressExists() {
  return Boolean(window.Cypress || window.parent.Cypress);
}

let activatedExperiments = [];
function identify() {}
let userId;

export function attachUserId(_userId) {
  log('ATTACHING USER ID', _userId, 'PREVIOUSLY', userId);
  userId = _userId;
}

export function resetOptimizely(userProfileService) {
  log('RESETTING OPTIMIZELY', userProfileService);
  return reinit(ENVIRONMENT, userProfileService);
}

export function attachIdentify(_identify) {
  // eslint-disable-next-line no-func-assign
  identify = _identify;
}

function constructAttributes(attributes = {}) {
  return {
    ...getAttributes(),
    ...attributes,
  };
}

/**
 * @function getVariation
 * @param {string} experiment the name of the experiment
 * @param {object} [attributes] any additional attributes to pass to Optimizely
 * @param {boolean} [activate=true] whether to activate the experiment on first call
 * @return {Promise<string>} variation the experiment variation for the user,
 *   or null if the experiment is inactive or Optimizely is unavailable.
 *
 * Used to get the variation of an experiment the user should be placed in, and
 * activates the user in the experiment if they haven’t been activated yet.
 * Uses the current mover app environment, event tracker attributes, and userId
 * as set by `attachUserId`.
 *
 */
export async function getVariation(
  experiment,
  attributes = {},
  activate = true
) {
  if (!userId) {
    console.error(
      `getVariation ${experiment} called before userId was initialized`
    );
    return null;
  }

  const activating =
    activate && !activatedExperiments.includes(experiment) && !cypressExists();

  const variation = await (activating
    ? _activate(
        ENVIRONMENT,
        experiment,
        userId,
        generateEventAttributes(constructAttributes(attributes))
      )
    : _getVariation(
        ENVIRONMENT,
        experiment,
        userId,
        generateEventAttributes(constructAttributes(attributes))
      ));

  track({
    domain: 'mover',
    object: 'experiment',
    verb: TRACK,
    details: {
      experiments: {
        [experiment]: variation,
      },
    },
  });

  if (activating) {
    activatedExperiments = [...activatedExperiments, experiment];

    track({
      domain: 'mover',
      object: 'user',
      verb: UPDATE,
      details: {
        experiments: {
          [experiment]: variation,
        },
      },
    });

    identify(userId, {
      [`upd_experiment.${experiment}`]: variation,
    });
  }

  return variation;
}

/**
 * @function isFeatureEnabled
 * @param {string} feature the name of the feature
 * @param {object} [attributes] any additional attributes to pass to Optimizely
 * @return {Promise<boolean>} whether the feature is enabled for the user,
 *   or null if Optimizely is unavailable.
 *
 * Used to determine if a feature is enabled for a user, and activates the user
 * in the associated feature test if they haven’t been activated yet.
 * Uses the current mover app environment, event tracker attributes, and userId
 * as set by `attachUserId`.
 *
 */
export async function isFeatureEnabled(feature, attributes = {}) {
  if (!userId) {
    console.error(
      `isFeatureEnabled ${feature} called before userId was initialized`
    );
    return null;
  }

  const { experiment } = (await getFeatureTest(ENVIRONMENT, feature)) || {};
  const enabled = await _isFeatureEnabled(
    ENVIRONMENT,
    feature,
    userId,
    constructAttributes(attributes)
  );

  if (
    experiment &&
    !activatedExperiments.includes(experiment) &&
    !cypressExists()
  ) {
    // We can use featureEnabledToVariation but instead we double-check with
    // getVariation in case the user isn’t allocated into the test
    const variation = await _getVariation(
      ENVIRONMENT,
      experiment,
      userId,
      generateEventAttributes(constructAttributes(attributes))
    );

    if (variation) {
      track({
        domain: 'mover',
        object: 'user',
        verb: UPDATE,
        details: {
          experiments: {
            [experiment]: variation,
          },
        },
      });

      identify(userId, {
        [`upd_experiment.${experiment}`]: variation,
      });
    }
  }

  track({
    domain: 'mover',
    object: 'feature',
    verb: TRACK,
    details: {
      features: {
        [feature]: enabled,
      },
    },
  });

  return enabled;
}

/**
 * @function getFeatureVariables
 * @param {string} feature the name of the feature
 * @param {object} variablesToGet a map of variable name to Optimizely variable type. Valid types are: `string`, `integer`, `double`, and `boolean`.
 * @param {object} [attributes] any additional attributes to pass to Optimizely
 * @return {Promise<boolean>} whether the feature is enabled for the user,
 *   or null if Optimizely is unavailable.
 *
 * Used to get specified feature variables.
 * Uses the current mover app environment, event tracker attributes, and userId
 * as set by `attachUserId`.
 *
 */
export async function getFeatureVariables(
  feature,
  variablesToGet,
  attributes = {}
) {
  if (!userId) {
    console.error(
      `getFeatureVariables ${feature} called before userId was initialized`
    );
    return null;
  }

  const variables = await _getFeatureVariables(
    ENVIRONMENT,
    feature,
    variablesToGet,
    userId,
    constructAttributes(attributes)
  );

  track({
    domain: 'mover',
    object: 'feature_variables',
    verb: TRACK,
    details: {
      features: {
        [feature]: {
          ...variables,
        },
      },
    },
  });

  return variables;
}
