import { ENVIRONMENT } from '@updater-core/lib/environment';
import { createLoggerFunc } from '@updater-core/lib/logger';

export function createLogger(...args) {
  return createLoggerFunc(
    ENVIRONMENT.debug?.optimizelyModule,
    '[OPTIMIZELY-MODULE]',
    'color: #4e223a'
  )(...args);
}
