import { Environment } from '../types';

export const docker: Environment = {
  name: 'docker',
  endpoints: {
    api: {
      url: '//api.staging.updater.com',
    },
    cloudinary: 'https://res.cloudinary.com/updater-staging/image/upload',
    optimizely:
      'https://cdn.optimizely.com/datafiles/PV8bgAg9tPhvowmGw4zz4X.json',
  },
  keys: {
    smartystreets: {
      authToken: '4537425833772681843',
    },
    stripe: 'pk_test_KZhy8xHQcPTYedpuiPekDh8w',
    segment: 'f2ZJEsBwVimRzNsLmTl4ys4G10T5Ntsu',
    sentry: 'https://1b861731eeec46199ff1d42514627cbc@sentry.io/53228',
  },
};
