import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@webcomponents/webcomponentsjs/webcomponents-lite';
import { polyfill as smoothscrollPolyfill } from 'smoothscroll-polyfill';
import 'element-closest';
import 'unfetch/polyfill';

smoothscrollPolyfill();

// Workaround for colliding custom elements definitions when linking dependencies.
// TODO: Consider breaking out into build helper.
const { define } = customElements;
customElements.define = (...args) => {
  try {
    define.apply(customElements, args);
  } catch (err) {
    console.warn(
      `customElements.define: "${args[0]}" has already been defined!`
    );
  }
};

// Polyfill for ChildNode.remove in IE11 (https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove)
(function(arr) {
  arr.forEach(function(item) {
    if (item.hasOwnProperty('remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode !== null) this.parentNode.removeChild(this);
      },
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

// https://github.com/tc39/proposal-object-values-entries/blob/master/polyfill.js
const reduce = Function.bind.call(Function.call, Array.prototype.reduce);
const isEnumerable = Function.bind.call(
  Function.call,
  Object.prototype.propertyIsEnumerable
);
const concat = Function.bind.call(Function.call, Array.prototype.concat);
const keys = Reflect.ownKeys;

if (!Object.values) {
  Object.values = function values(O) {
    return reduce(
      keys(O),
      (v, k) =>
        concat(v, typeof k === 'string' && isEnumerable(O, k) ? [O[k]] : []),
      []
    );
  };
}

if (!Object.entries) {
  Object.entries = function entries(O) {
    return reduce(
      keys(O),
      (e, k) =>
        concat(
          e,
          typeof k === 'string' && isEnumerable(O, k) ? [[k, O[k]]] : []
        ),
      []
    );
  };
}
