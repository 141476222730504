import { createGateway as createLocalStorageGateway } from '@updater-core/gateways/local-storage';
import { lightstepTracer } from '@updater-core/lib/tracing';
import { createInteractor } from './interactor';
import { createGateway as createRemoteGateway } from './remote.gateway';
import { createGateway as createStorageGateway } from './storage.gateway';

export function buildSession() {
    const remoteGateway = createRemoteGateway();
    const localStorageGateway = createLocalStorageGateway();
    const sessionStorageGateway = createStorageGateway({
      storageGateway: localStorageGateway,
    });
    const interactor = createInteractor({
      remoteGateway,
      storageGateway: sessionStorageGateway,
      window: typeof window === 'undefined' ? {} : window,
    });

    function addSessionTags(span) {
      const sessionState = interactor.getState();
      try {
        span.setTag(
          `session.entities`,
          JSON.stringify(interactor.selectors.entities(sessionState))
        );
        span.setTag('session.uuid', interactor.selectors.uuid(sessionState));
        span.setTag('session.test-user', interactor.selectors.test(sessionState));
        span.setTag(
          'session.channel',
          interactor.selectors.channelName(sessionState)
        );
        span.setTag(
          'session.authenticated',
          interactor.selectors.isAuthenticated(sessionState)
        );
      } catch (e) {
        span.log({
          message: 'errored attaching session',
          ...e,
        });
      }
    }

    lightstepTracer?.on('start_span', addSessionTags);

    return interactor;
}
