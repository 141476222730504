import {
  buildEnvironment,
  getBuildOptionsFromLocation,
} from './build-environment';

export function buildEnvironmentFromLocation(environments) {
  let location;

  if (typeof window !== 'undefined') {
    location = window.location;
  }

  return buildEnvironment(environments, getBuildOptionsFromLocation(location));
}
