// A map of environments to subdomain environment name
export const ENVIRONMENTS = {
  DEMO: 'demo',
  DEVELOPMENT: 'dev',
  DOCKER: 'docker',
  PRODUCTION: 'production',
  RUNNABLE: 'runnableapp',
  STAGING: 'staging',
  TEST: 'test',
  UPDATRON: 'updatron',
};

export const DEFAULT_ENV = ENVIRONMENTS.TEST;
