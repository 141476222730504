import { createGateway as createRemoteGateway } from '@updater-core/gateways/remote-storage';
import { SessionInteractor } from '@updater-core/interactors/shell/session/interactor.d';

/*
Local quick setup cache so that the gateway 
can be instatiated in multiple places of the app
without creating a refetch
{
  [moveId]: {
    done: true/false,
  };
}
*/
const quickSetupCache = {};

export function createQuickSetupGateway({
  session,
}: {
  session: SessionInteractor;
}) {
  const sessionState = session.getState();
  const uuid = session.selectors.uuid(sessionState);
  const requestHeaders = session.selectors.requestHeaders(sessionState);
  const moveId = session.selectors.currentMoveId(sessionState);

  const QUICK_SETUP_DONE_KEY = `upd.quick-setup-${moveId}`;

  const remoteGateway = createRemoteGateway(uuid, requestHeaders);

  function fetchDone(): Promise<boolean> {
    return quickSetupCache[moveId]
      ? Promise.resolve(Boolean(quickSetupCache[moveId].done))
      : remoteGateway.getJSON(QUICK_SETUP_DONE_KEY).then((result) => {
          quickSetupCache[moveId] = result || {};
          return Boolean(quickSetupCache[moveId].done);
        });
  }

  return {
    setDone: async () => {
      await fetchDone();
      quickSetupCache[moveId].done = true;
      return remoteGateway.setJSON(
        QUICK_SETUP_DONE_KEY,
        quickSetupCache[moveId]
      );
    },
    clearDone: async () => {
      await fetchDone();
      quickSetupCache[moveId].done = false;
      return remoteGateway.setJSON(
        QUICK_SETUP_DONE_KEY,
        quickSetupCache[moveId]
      );
    },
    fetchDone,
  };
}
