import { StorageGateway } from '@updater-core/gateways/interfaces';
import get from 'lodash/get';
import set from 'lodash/set';
import omit from 'lodash/omit';

type StorageMiddlewareProps = {
  storageKey: string;
  storage: Pick<StorageGateway, 'getJSON' | 'setJSON'>;
  includeKeys: string[];
  omitKeys?: string[];
};
/**
 * create a middleware which persists keys into a store
 * 
 * @param {string} config.storageKey - The key for storing into provided storage gateway
 * @param {StorageGateway} config.storage - The storage gateway - should provide getJSON and setJSON
 * @param {string[]} config.includeKeys - array of paths of keys to include in the stored value
 * @param {string[]} [config.omitKeys] - array of paths to omit from the included keys in stored value
 * @example
```js
import { createStore, storageMiddleware } from '@updater-core/lib/store';
import { createGateway as createLocalGateway } from '@updater-core/gateways/local-storage';

const storage = createLocalGateway();
const storageKey = 'some_storage_key';
const storedState = storage.getJSON(storageKey);

const initialState = {
  ...defaultState,
  ...storedState
}

const store = createStore(
  initialState, 
  storageMiddleware({
    storageKey,
    storage,
    includeKeys: ['someKey', 'some.deepKey']
  })
)
```
 */
export function storageMiddleware({
  storageKey,
  storage,
  includeKeys,
  omitKeys = [],
}: StorageMiddlewareProps) {
  return (middlewareStore) => () => () => {
    const state = middlewareStore.getState();
    let storedValue = includeKeys.reduce((acc, key) => {
      const value = get(state, key);
      set(acc, key, value);
      return acc;
    }, {});

    storedValue = omit(storedValue, omitKeys);

    storage.setJSON(storageKey, storedValue);
  };
}
