import merge from 'lodash/merge';
import { tracker } from '@updater-core/lib/tracker/tracker';
import {
  RECEIVE,
  PAGE,
  VIEW,
  CHANGE,
  CLICK,
  SELECT,
  CLOSE,
  SUBMIT,
  DISPLAY,
  TRACK,
  UPDATE,
  MEASURE,
  BOUNCE,
  ERROR,
  WARNING,
  PURCHASE,
  DECLINE,
  ENROLL,
  UPLOAD,
  ENGAGE,
  SENT,
  FAIL,
} from '@updater-core/lib/tracker/constants';
import createClientSessionId from '@updater-core/interactors/shell/tracker/utils/createClientSessionId';
import generateBaseAttributes from '@updater-core/interactors/shell/tracker/utils/generateBaseAttributes';
import trackAll from '@updater-core/interactors/shell/tracker/trackAll';
import { attachIdentify } from '@updater-core/lib/optimizely';
import { logger } from './logger';

const { log } = logger;

export const PARENT_SESSION_ID_KEY = 'upd_parent_session_id';

export function buildEventTracker({ session }) {
  let ready = false;
  const queue = [];

  function handleLoaded() {
    log(`Application loaded. Tracking ${queue.length} queued events.`);
    queue.forEach(({ event, resolve }) => {
      resolve(trackAll(event, { session }));
    });
    ready = true;
  }
  if (typeof window !== 'undefined') {
    window.addEventListener('load', handleLoaded);
  }

  let source;

  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search);
    source = params.get('src');
  }

  const clientSessionId = createClientSessionId();
  let eventstreamAttributes = {};
  let distanceType;

  // DEPRECATED: We are keeping this function  here for legacy
  // reasons as some parts of our app still rely on `getAttributes` for access
  // to certain global attributes. We want to move away from this pattern.
  tracker.setAttributes(generateBaseAttributes({ clientSessionId, source }));

  const unsubscribe = tracker.subscribe((trackTimeAttributes) => {
    if (typeof window !== 'undefined') {
      const baseAttributes = generateBaseAttributes({
        clientSessionId,
        source,
      });

      const event = merge(
        {},
        baseAttributes,
        eventstreamAttributes,
        {
          user: {
            move: {
              distance_type: distanceType,
            },
          },
        },
        trackTimeAttributes
      );

      if (!ready) {
        log(
          `${event.domain}.${event.object}.${event.verb} fired, but not ready, queueing.`
        );
        let resolve;
        const promise = new Promise((res) => {
          resolve = res;
          return resolve;
        });
        queue.push({
          event,
          resolve,
        });
        return promise;
      }
      return trackAll(event, { session });
    }
    return undefined;
  });

  const unsubscribeSession = session.subscribe(async () => {
    const state = session.getState();
    const newEventstreamAttributes =
      session.selectors.eventStreamAttributes(state);

    eventstreamAttributes = newEventstreamAttributes;
    // DEPRECATED: We are keeping this function  here for legacy
    // reasons as some parts of our app still rely on `getAttributes` for access
    // to certain global attributes. We want to move away from this pattern.
    tracker.setAttributes(
      merge({}, tracker.getAttributes(), newEventstreamAttributes)
    );

    if (typeof window !== 'undefined') {
      const uuid = session.selectors.uuid(state);

      if (window.analytics) {
        attachIdentify(window.analytics.identify);
      }

      if (window.analytics && uuid) {
        window.analytics.identify(uuid, {
          firstName: session.selectors.userFirstName(state),
          lastName: session.selectors.userLastName(state),
          email: session.selectors.userEmail(state),
          phone: session.selectors.userPhoneNumber(state),
        });
      } else if (window.analytics) {
        window.analytics.reset();
      }
    }
  });

  return {
    actions: {
      track: tracker.track,
    },
    selectors: {
      attributes: tracker.getAttributes,
    },
    destroy: () => {
      unsubscribeSession();
      unsubscribe();
      window.removeEventListener('load', handleLoaded);
    },
    RECEIVE,
    PAGE,
    VIEW,
    CHANGE,
    CLICK,
    SELECT,
    CLOSE,
    SUBMIT,
    DISPLAY,
    TRACK,
    UPDATE,
    MEASURE,
    BOUNCE,
    ERROR,
    WARNING,
    PURCHASE,
    DECLINE,
    ENROLL,
    UPLOAD,
    ENGAGE,
    SENT,
    FAIL,
  };
}
