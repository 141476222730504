import gql from 'graphql-tag';
import fetch from '@updater-core/lib/fetch';
import { print } from 'graphql/language/printer';
import { addTypenameToDocument } from 'apollo-utilities';
import { track, ERROR, MEASURE } from '@updater-core/lib/tracker';
import { ENVIRONMENT } from '@updater-core/lib/environment';
import { startSpan, tagError } from '@updater-core/lib/tracing';

// Copied from home-services-app
function omitTypename(key, value) {
  return key === '__typename' ? undefined : value;
}

export function removeTypename(variables) {
  return JSON.parse(JSON.stringify(variables), omitTypename);
}

export function fetchGraphQLQuery({
  headers,
  query,
  variables,
  url,
  parentSpan,
}: {
  headers: any;
  query: any;
  variables: any;
  url: string;
  parentSpan?: Function;
}) {
  // consumers of api can pass in gql tag or string
  let operationName = query.definitions
    ? query.definitions.find(({ kind }) => kind === 'OperationDefinition').name
        .value
    : null;

  // we convert to gql tag to support strings
  const graphqQuery = gql`
    ${query}
  `;

  // if no operation name was found, consumer may have passed in string (not gql tag)
  // check our query for operation name prior to making the request
  if (!operationName) {
    const definition = graphqQuery.definitions?.find(
      ({ kind }) => kind === 'OperationDefinition'
    );

    // Repeat kind check to inform TS of the subtype
    operationName =
      definition.kind === 'OperationDefinition' ? definition.name.value : null;
  }
  const span = startSpan(`fetchGraphQLQuery`, {
    childOf: parentSpan,
  });
  span.setTag('operationName', operationName);
  span.setTag('url', url);

  const start = Date.now();
  return (
    fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        app: 'mover',
        'Content-Type': 'application/json',
        'apollographql-client-name': 'mover-app',
      },
      body: JSON.stringify({
        query: print(addTypenameToDocument(graphqQuery)),
        variables: variables ? removeTypename(variables) : undefined,
      }),
      // @ts-ignore
      parentSpan: span,
    })
      .then((response) => {
        const end = Date.now();

        track({
          object: 'graphql_request',
          verb: MEASURE,
          details: {
            duration: end - start,
            operationName,
          },
        });

        return response.json();
      })
      .then((data) => {
        try {
          /* eslint-disable no-console */
          const shouldLog = ENVIRONMENT?.debug?.graphQl;
          if (shouldLog) {
            console.log(`%c[GRAPHQL] - ${operationName} -`, 'color: #bf5700', {
              variables,
              result: data,
            });
          }
          /* eslint-enable */
        } catch (e) {} // eslint-disable-line no-empty

        if (data.errors) {
          span.setTag('error', true);
          data.errors.forEach(
            ({ message, locations, fields, stack, ...rest }, i) => {
              span.setTag(`error.message.${i}`, message);
              track({
                object: 'graphql_query',
                verb: ERROR,
                details: {
                  operationName,
                  message,
                  stack,
                  locations,
                  fields,
                  ...rest,
                },
              });
            }
          );
        }
        span.finish();
        return data;
      })
      // eslint-disable-next-line prefer-arrow-callback
      .catch(function handleGraphQLError(error) {
        tagError(span, error);
        span.finish();
        track({
          object: 'graphql_query',
          verb: ERROR,
          details: {
            message: error.message,
            stack: error.stack,
            operationName,
          },
        });
      })
  );
}
