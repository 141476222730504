/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-console, no-unused-expressions */
/**
 * @module Optimizely
 *
 * @description
 *
 * This module contains helper functions that get information about optimizely
 * experiments and features.
 *
 * ## Forcing Features and Experiments
 *
 * When testing optimizely tests it's frequently desired to force features,
 * feature variables and experiments. This can be done via localStorage storage:
 *
 * - **Forcing an Experiment Value**: `localStorage.setItem('upd_experiment.<experiment_name>', '<variation_name'>);`
 * - **Forcing a Feature**: `localStorage.setItem('upd_feature.<feature_name>', JSON.stringify({enabled: 'true'}));`
 * - **Forcing feature variables**: `localStorage.setItem('upd_feature.<feature_name>', JSON.stringify({enabled: 'true', variables: {...variables}}));`
 *
 * Note: Forcing a feature experiment will not force the variables to align with
 * the experiment. You must also force the feature variables.
 *
 * ## Tracking Revenue
 *
 * Tracking revenue is done by adding the `revenue` to details you log in events
 * For example, when using `@updater-core/lib/tracker`, the
 * following will associate a revenue of $5 with the event
 * `mover.tv_internet_buy.submitted`
 *
 * ```js
 * track({
 *   domain: 'mover',
 *   verb: SUBMIT,
 *   object: 'submitted',
 *   details: {
 *     revenueInCents: 500,
 *   }
 * });
 * ```
 *
 * ## A note on currying
 *
 * The majority of exported functions for this module are curried with Ramda.
 * This means that they can be called with any number of arguments. If called
 * without their full set of arguments they will return a function that expects
 * the remaining arguments. For example the `getVariation` function takes 3 and
 * the following are all valid:
 * - `getVariation(env)(experimentName)(userId)(attributes)`
 * - `getVariation(env, experimentName)(userId)(attributes)`
 * - `getVariation(env, experimentName, userId)(attributes)`
 * - `getVariation(env, experimentName, userId, attributes)`
 *
 * In addition the placeholder from Ramda can be used to hold a place in any of
 * these curried functions. For example if you know the `data` and `env` but not
 * the experiment name you can do the following:
 *
 * ```js
 * import { __ as placeholder } from 'ramda';
 * import { getVariation } from '@updater-core/lib/optimizely';
 *
 * const isInExperiment = getVariation(env, placeholder, data);
 * if (isInExperiment('TEST_NAME')) { // do test stuff }
 * ```
 */
import isUndefined from 'lodash/isUndefined';
import isObject from 'lodash/isObject';
import snakeCase from 'lodash/snakeCase';
import get from 'lodash/get';
import difference from 'lodash/difference';
import union from 'lodash/union';
import curry from 'lodash/curry';
import optimizelySDK from '@optimizely/optimizely-sdk';
import { ENVIRONMENT } from '@updater-core/lib/environment';
import { createLogger } from '@updater-core/lib/logger';
import fetch from '@updater-core/lib/fetch';

if (isUndefined(fetch)) {
  console.error(
    'Optimizely failed to initialize due to missing fetch. Please ensure fetch is available in the environment.'
  );
}

let activatedExperiments = [];

const callbackQueue = [];

function cypressExists() {
  return Boolean(window.Cypress || window.parent.Cypress);
}

// determine the log level to set based on the environment config
// accept a number (per https://github.com/optimizely/node-sdk/blob/master/lib/utils/enums/index.js#L20-L26)
// Translate `true` to 1 (DEBUG) and `false` or `undefined` to 3 (WARN + ERROR)
function logLevel(env) {
  const debug = env && env.debug && env.debug.optimizely;
  return debug === true
    ? 1
    : debug === false || debug === undefined
    ? 3
    : debug;
}

const storage = {
  getFeatures: () =>
    Object.keys(localStorage)
      .filter((key) => key.includes('upd_feature'))
      .map((key) => ({
        name: key.split('.')[1],
        ...JSON.parse(localStorage[key]),
      })),
  getFeature: (key) => {
    const feature = localStorage.getItem(`upd_feature.${key}`);

    return feature
      ? {
          ...JSON.parse(feature),
          name: key,
        }
      : undefined;
  },
  getExperiment: (key) => {
    const variation = localStorage.getItem(`upd_experiment.${key}`);
    return variation
      ? {
          name: key,
          variation,
        }
      : undefined;
  },
};

export function flatten({ delimiter = '.' } = {}) {
  return function recurse(source, path) {
    if (isUndefined(source)) {
      return {};
    }

    if (!isObject(source) || !Object.keys(source).length)
      return { [path]: source };

    return Object.keys(source).reduce((obj, key) => {
      const value = source[key];
      if (Array.isArray(value)) return obj;

      const deeperPath = path ? path + delimiter + key : key;
      return Object.assign(obj, recurse(value, deeperPath));
    }, {});
  };
}

export function generateEventName({ domain, object, verb }) {
  return [domain || '_', object || '_', verb || '_']
    .map((item) => snakeCase(item))
    .join('.');
}

export function generateEventNameWithSuffix({
  domain,
  object,
  verb,
  details: {
    name = undefined,
    code = undefined,
    validity = undefined,
    section = undefined,
    key = undefined,
    value = undefined,
    Code = undefined, // Capitalized due to legacy Segment => ES conversion
    waitTime = undefined,
  } = {},
}) {
  return (
    generateEventName({ domain, object, verb })
      .concat(object === 'step' && name ? `-${snakeCase(name)}` : '')
      .concat(
        object === 'unlock_deal' && verb === 'submitted' && code
          ? `-${snakeCase(code)}`
          : ''
      )
      .concat(
        object === 'exclusive_offer' && verb === 'displayed' && code
          ? `-${snakeCase(code)}`
          : ''
      )
      .concat(
        object === 'splash_page_copy_code' && verb === 'clicked' && code
          ? `-${snakeCase(code)}`
          : ''
      )
      .concat(
        object === 'redeem' && verb === 'clicked' && code
          ? `-${snakeCase(code)}`
          : ''
      )
      .concat(
        object === 'to_address' && verb === 'submitted' && validity
          ? `-${snakeCase(validity)}`
          : ''
      )
      .concat(
        object === 'step_card' && verb === 'clicked' && Code
          ? `-${snakeCase(Code)}`
          : ''
      )
      .concat(
        (object === 'checkout_change_section' ||
          object === 'checkout_section') &&
          verb === 'clicked' &&
          section
          ? `-${snakeCase(section)}`
          : ''
      )
      .concat(
        object === 'checkout_input' &&
          verb === 'changed' &&
          key === 'termsAndConditionsOptIn'
          ? `-t_and_c_opt_in-${value}` // Optimizely event name can't be longer than 64
          : ''
      )
      .concat(
        object === 'checkout_input' && verb === 'changed' && key === 'cpniOptIn'
          ? `-cpni_opt_in-${value}`
          : ''
      )
      // Add code to one_click_move events
      .concat(
        domain === 'one_click_move' &&
          code &&
          ((verb === 'tracked' &&
            (object === 'remove_from_cart' || object === 'add_to_cart')) ||
            (verb === 'clicked' &&
              (object === 'learn_more' ||
                object === 'close_details' ||
                object === 'remove_item_button' ||
                object === 'add_item_button' ||
                object === 'remove_in_checkout')))
          ? `-${snakeCase(code)}`
          : ''
      )
      .concat(
        domain === 'home_services' &&
          object === 'search_offers_wait_time' &&
          waitTime > 30000
          ? '-over_thirty_s'
          : domain === 'home_services' &&
            object === 'search_offers_wait_time' &&
            waitTime > 10000
          ? '-ten_to_thirty_s'
          : domain === 'home_services' &&
            object === 'search_offers_wait_time' &&
            waitTime > 3000
          ? '-three_to_ten_s'
          : domain === 'home_services' &&
            object === 'search_offers_wait_time' &&
            waitTime > 1000
          ? '-one_to_three_s'
          : domain === 'home_services' && object === 'search_offers_wait_time'
          ? '-below_one_s'
          : ''
      )
  );
}

export function generateEventAttributes(event) {
  return flatten({ delimiter: '_' })(event);
}

export function eventValidator(datafile) {
  return (name) => datafile.events.map((event) => event.key).includes(name);
}

// Create our custom event dispatcher so that we can resolve
// the track promise once the XHR request completes
function createCustomEventDispatcher() {
  const { eventDispatcher } = optimizelySDK;
  const { dispatchEvent } = eventDispatcher;

  function flattenArray(prop) {
    return (flat, item) => flat.concat(prop ? item[prop] : item);
  }

  function ourEventDispatcher(eventObj, callback) {
    function ourCallback(...args) {
      try {
        // use the eventObj to figure out which event to resolve from the callbackQueue
        // by flattening the following structure to find events
        // eventObj.params.visitors[].snapshots[].events[]
        const allEvents = eventObj.params.visitors
          .reduce(flattenArray('snapshots'), [])
          .reduce(flattenArray('events'), []);

        // find all items in the callback Queue which match the events being sent in this req
        allEvents.forEach(({ key }) => {
          const callbackItemIndex = callbackQueue.findIndex(
            ({ eventName }) => eventName === key
          );
          const callbackItem = callbackQueue[callbackItemIndex];
          if (callbackItem) {
            callbackItem.resolve();
            callbackQueue.splice(callbackItemIndex, 1);
          }
        });
      } catch (ex) {
        console.log(ex);
      }
      // call their callback because we probably should
      callback(...args);
    }

    // make the XHR request with our callback which wraps theirs
    dispatchEvent(eventObj, ourCallback);
  }

  eventDispatcher.dispatchEvent = ourEventDispatcher;

  return eventDispatcher;
}

let datafile;
let datafilePromise;
let client;

export function fetchDatafile(env) {
  return fetch(env.endpoints.optimizely).then((res) => res.json());
}

export function createClient({ datafile, env, userProfileService = null }) {
  client = optimizelySDK.createInstance({
    datafile,
    userProfileService,
    logLevel: logLevel(env),
    // https://docs.developers.optimizely.com/full-stack/docs/configure-the-event-dispatcher
    eventDispatcher: createCustomEventDispatcher(),
  });
  logLevel(env) === 1 &&
    console.log(
      '[ui-optimizely][init] Initializing Optimizely',
      env,
      userProfileService
    );

  return client;
}

export async function init(env, userProfileService = null) {
  // If we have a `datafile` and a `client` we can just resolve a promise with them.
  if (datafile && client) {
    return { datafile, client };
  }

  // If we don't have a `datafilePromise`, fetch `datafile`.
  if (!datafilePromise) {
    datafilePromise = fetchDatafile(env);
  }

  // Resolve with `datafile` and `client` once `datafile` is received.
  return datafilePromise.then((df) => {
    datafile = df;
    client = createClient({
      datafile,
      env,
      userProfileService,
    });
    window.optimizelyClient = client;
    return { datafile, client };
  });
}

export function reinit(env, userProfileService = null) {
  datafile = undefined;
  client = undefined;
  return init(env, userProfileService);
}

// eslint-disable-next-line no-underscore-dangle
export function _hasBeenActivated(init) {
  return (env) => (experiment) =>
    init(env).then(({ datafile, client }) => {
      const { id } =
        datafile.experiments.find(({ key }) => key === experiment) || {};
      if (!id) return false;

      try {
        // eslint-disable-next-line camelcase
        const { variation_id } =
          client.decisionService.userProfileService.lookup()
            .experiment_bucket_map[id] || {};
        return Boolean(variation_id);
      } catch (e) {
        console.error(
          '[ui-optimizely] Failed to access userProfileService for activation lookup',
          e
        );
        return false;
      }
    });
}

export const hasBeenActivated = _hasBeenActivated(init);

// eslint-disable-next-line no-underscore-dangle
export const _getVariation = curry(
  (init, storage, env, experiment, userId, attributes) => {
    const storedExperiment = storage.getExperiment(experiment);
    if (storedExperiment) {
      return Promise.resolve(storedExperiment.variation);
    }
    return init(env).then(
      ({ client }) =>
        client.getVariation(
          experiment,
          userId,
          generateEventAttributes(attributes)
        ),
      () => null
    );
  }
);

/**
 * @function getVariation
 * @param {object} env an environment object with `env.endpoints.optimizely`
 * @param {string} experimentName the name of the experiment
 * @param {string} userId
 * @param {object} attributes the attributes to associate with the experiment
 * @returns {string} the variation
 *
 * Used to get the variation of an experiment the user should be placed in.
 * Curried with Ramda. Arity of 4.
 */
export function getVariation(...args) {
  return _getVariation(init, storage)(...args);
}

// eslint-disable-next-line no-underscore-dangle
export const _activate = curry(
  (init, storage, env, experiment, userId, attributes) => {
    const storedExperiment = storage.getExperiment(experiment);
    if (storedExperiment) {
      return Promise.resolve(storedExperiment.variation);
    }
    return init(env).then(
      ({ client }) =>
        client.activate(
          experiment,
          userId,
          generateEventAttributes(attributes)
        ),
      () => null
    );
  }
);

/**
 * @function activate
 * @param {object} env an environment object with `env.endpoints.optimizely`
 * @param {string} experimentName the name of the experiment
 * @param {string} userId
 * @param {object} attributes the attributes to associate with the experiment
 * @returns {string} the variation
 *
 * Used to activate a user in an Optimizely experiment. Returns the variation
 * of an experiment the user is placed in.
 * Curried with Ramda. Arity of 4.
 */
export const activate = _activate(init, storage);

export const _getVariationOrActivate = curry(
  (init, storage, env, experiment, userId, attributes, activate = true) => {
    if (
      !activate ||
      activatedExperiments.includes(experiment) ||
      cypressExists()
    ) {
      return _getVariation(init, storage, env, experiment, userId, attributes);
    }
    activatedExperiments = [...activatedExperiments, experiment];
    return _activate(init, storage, env, experiment, userId, attributes);
  }
);

/**
 * @function getVariationOrActivate
 * @param {object} env an environment object with `env.endpoints.optimizely`
 * @param {string} experimentName the name of the experiment
 * @param {string} userId
 * @param {object} attributes the attributes to associate with the experiment
 * @param {boolean} activate whether to allow activation or not. Defaults to true
 * @returns {string} the variation
 *
 * Used to get the variation of an experiment the user should be placed in, and
 * activates the user in the experiment if they haven’t been activated yet.
 * Curried with Ramda. Arity of 4.
 */
export const getVariationOrActivate = _getVariationOrActivate(init, storage);

export const _isFeatureEnabled = curry(
  (init, storage, env, feature, userId, attributes) => {
    if (storage.getFeature(feature)) {
      const featureInStorage = storage.getFeature(feature).enabled;
      return Promise.resolve(
        featureInStorage === true || featureInStorage === 'true'
      );
    }
    return init(env).then(
      ({ client }) =>
        client.isFeatureEnabled(
          feature,
          userId,
          generateEventAttributes(attributes)
        ),
      () => null
    );
  }
);

/**
 * @function isFeatureEnabled
 * @param {object} env an environment object with `env.endpoints.optimizely`
 * @param {string} featureName the name of the feature
 * @param {string} userId
 * @param {object} attributes the attributes to associate with the feature
 * @returns {boolean} if the feature is enabled
 *
 * Used to determine if a feature is enabled for a user.
 * Curried with Ramda. Arity of 4.
 */
export const isFeatureEnabled = _isFeatureEnabled(init, storage);

export const _getEnabledFeatures = curry(
  (init, storage, env, userId, attributes) =>
    Promise.all([
      init(env).then(({ client }) =>
        client.getEnabledFeatures(userId, generateEventAttributes(attributes))
      ),
      storage
        .getFeatures()
        .filter((feature) => feature.enabled)
        .map((feature) => feature.name),
      storage
        .getFeatures()
        .filter((feature) => !feature.enabled)
        .map((feature) => feature.name),
    ]).then(
      ([
        remoteEnabledFeatures,
        clientEnabledFeatures,
        clientDisabledFeatures,
      ]) =>
        difference(
          union(remoteEnabledFeatures, clientEnabledFeatures),
          clientDisabledFeatures
        ),
      () => null
    )
);

/**
 * @function getEnabledFeatures
 * @param {object} env an environment object with `env.endpoints.optimizely`
 * @param {string} userId
 * @param {object} attributes the attributes to associate with the feature
 * @returns {array} an array containing the names of all enabled features
 *
 * Used to determine all features enabled for the user.
 * Curried with Ramda. Arity of 3.
 */
export const getEnabledFeatures = _getEnabledFeatures(init, storage);

function convertFeatureVariableType(variable, type) {
  if (type === 'boolean') {
    return Boolean(variable);
  }
  return variable;
}

export const _getFeatureVariables = curry(
  (init, storage, env, feature, variablesToGet, userId, attributes) => {
    if (storage.getFeature(feature)) {
      return Promise.resolve(
        Object.entries(variablesToGet)
          .map(([key, value]) => ({
            [key]: convertFeatureVariableType(
              get(storage.getFeature(feature), ['variables', key]),
              value
            ),
          }))
          .reduce((result, value) => ({ ...result, ...value }), {})
      );
    }
    return init(env).then(
      ({ client }) =>
        Promise.all(
          Object.keys(variablesToGet).map((variableKey) => {
            const variableType = variablesToGet[variableKey];
            // getFeatureVariableString
            // getFeatureVariableBoolean
            const methodName = `getFeatureVariable${variableType[0].toUpperCase()}${variableType
              .slice(1)
              .toLowerCase()}`;
            const value = client[methodName](
              feature,
              variableKey,
              userId,
              generateEventAttributes(attributes)
            );
            return { [variableKey]: value };
          })
        ).then((values) =>
          values.reduce((result, value) => ({ ...result, ...value }), {})
        ),
      () => null
    );
  }
);

/**
 * @function getFeatureVariables
 * @param {object} env an environment object with `env.endpoints.optimizely`
 * @param {string} featureName the name of the feature
 * @param {object} variablesToGet a map of variable name to optimizely variable type. Valid types are: `string`, `integer`, `double`, and `boolean`.
 * @param {string} userId
 * @param {object} attributes the attributes to associate with the feature
 * @returns {object} an object that contains variables that are associated with the feature
 *
 * Used to get specified feature variables.
 * `variablesToGet` should have the variable name as key and type as value.
 * ```js
 * {
 *   delay: 'integer'
 * }
 * ```
 * Curried with Ramda. Arity of 4.
 */
export const getFeatureVariables = _getFeatureVariables(init, storage);

const { log } = createLogger(
  ENVIRONMENT.debug?.eventTracker,
  '[EVENT][OPTIMIZELY]',
  'color: #AA00FF'
);

export function _track({
  datafile,
  client,
  env,
  userId,
  event,
  tags = {},
  callbackQueue,
  transforms = [],
}) {
  return Promise.all(
    Array.from(
      new Set(
        [
          generateEventName(event),
          generateEventNameWithSuffix(event),
          ...transforms?.map((t) => t(event)),
        ].filter((eventName) => !!eventName)
      )
    ).map((eventName) => {
      logLevel(env) === 1 &&
        console.log(
          '[ui-optimizely][event_validation] validating event for optimizely:',
          eventName
        );
      if (eventValidator(datafile)(eventName)) {
        const attributes = generateEventAttributes(event);
        let timeout;

        return Promise.race([
          new Promise((resolve) => {
            callbackQueue.push({
              eventName,
              resolve: () => {
                logLevel(env) === 1 &&
                  console.log(
                    '[ui-optimizely][resolve] track resolved for event:',
                    eventName
                  );
                if (timeout) {
                  clearTimeout(timeout);
                }
                resolve();
              },
            });

            log(eventName, userId, attributes, tags);
            client.track(eventName, userId, attributes, tags);
          }),
          new Promise((resolve) => {
            timeout = setTimeout(() => {
              logLevel(env) === 1 &&
                console.log(
                  '[ui-optimizely][resolve] Optimizely track never resolved for event:',
                  eventName
                );
              resolve();
            }, 2000);
          }),
        ]);
      }

      return eventName;
    })
  );
}

export const track = curry((env, userId, event, tags, transforms) =>
  init(env).then(
    ({ datafile, client }) =>
      _track({
        datafile,
        client,
        env,
        userId,
        event,
        callbackQueue,
        tags,
        transforms,
      }),
    () => null
  )
);

export function _getFeatureTest(init) {
  return (env, feature) =>
    init(env).then(
      ({ datafile }) => {
        const featureConfig = datafile.featureFlags.find(
          ({ key }) => key === feature
        );

        if (featureConfig && featureConfig.experimentIds.length > 0) {
          // Even though experimentIds is an array,
          // Optimizely only allows one feature test per feature
          const experiment = datafile.experiments.find(
            ({ id }) => id === featureConfig.experimentIds[0]
          );

          return {
            experiment: experiment && experiment.key,
            featureEnabledToVariation: (enabled) => {
              const variation = experiment.variations.find(
                ({ featureEnabled }) => featureEnabled === enabled
              );

              return variation && variation.key;
            },
          };
        }

        return { experiment: null };
      },
      () => null
    );
}

/**
 * @typedef {object} FeatureTest
 * @property {string} experiment the name of the feature test, or null if there isn’t one
 * @property {object} featureEnabledToVariation a map of the feature enabled
 * value to the feature test variation name
 */

/**
 * @function getFeatureTest
 * @param {object} env an environment object with `env.endpoints.optimizely`
 * @param {string} featureName the name of the feature
 * @returns {FeatureTest} an object that contains found experiment data
 *
 * Used to get an experiment, if it exists, that is a feature test for the
 * given feature
 */
export const getFeatureTest = _getFeatureTest(init);
