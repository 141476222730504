import { Environment } from '../types';

export const sandbox: Environment = {
  name: 'sandbox',
  cookieRootDomain: 'staging.updater.com',
  endpoints: {
    api: {
      url: '//api.sandbox.updater.com',
    },
    cloudinary: 'https://res.cloudinary.com/updater-staging/image/upload',
    optimizely:
      'https://cdn.optimizely.com/datafiles/PYZ6JzCsWqvJNfFVhJAt4d.json',
    eventstream: 'https://eventstream-api.sandbox.updatron.com/xyz',
  },
  keys: {
    smartystreets: {
      authToken: '4537425833973228200',
    },
    eventstream: {
      authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQHVwZGF0ZXIuY29tIiwic3ViIjoiY2xpY2tzdHJlYW0iLCJpYXQiOjE1NDAzMTAzNzMsImlzcyI6IlVwZGF0ZXIifQ.QvQewiy9QX031Iw2Zo9uD-zE-le42EXxeM-_1RkmoU8',
    },
    selectquote: {
      authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQHVwZGF0ZXIuY29tIiwic3ViIjoiY2xpY2tzdHJlYW0iLCJpYXQiOjE1NDAzMTAzNzMsImlzcyI6IlVwZGF0ZXIifQ.QvQewiy9QX031Iw2Zo9uD-zE-le42EXxeM-_1RkmoU8',
    },
    stripe: 'pk_test_KZhy8xHQcPTYedpuiPekDh8w',
    segment: 'n1qdc5i9mn',
    sentry: 'https://9593b71c96944d34965c760f1ce50681@sentry.io/53544',
  },
};
