/**
 * Wraps window.fetch function so we use the raw window fetch
 * on client over what NextJS implements when it bundles the JS
 */
export default function updaterFetch(
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> {
  if (typeof window !== 'undefined') {
    return window.fetch(input, init);
  }
  return fetch(input, init);
}
