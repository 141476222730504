import snakeCase from 'lodash/snakeCase';

export default function generateEventNameWithPrefix({
  events,
  prefix,
  condition,
}) {
  return (event) => {
    const { domain, object, verb } = event;
    const eventName = [domain || '_', object || '_', verb || '_']
      .map((item) => snakeCase(item))
      .join('.');

    if (events.includes(eventName) && condition(event)) {
      return `${prefix}${eventName}`;
    }
    return undefined;
  };
}
