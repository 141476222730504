import { ENVIRONMENT } from '@updater-core/lib/environment';
import { DOMAIN } from '@updater-core/interactors/shell/tracker/constants';

export default function generateBaseAttributes ({ clientSessionId, source }) {
  const connection =
    typeof navigator !== 'undefined' &&
    (navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection);

  let network;
  if (connection) {
    const { type, effectiveType } = connection;
    network = { type, effectiveType };
  }

  let innerWidth;
  let innerHeight;
  let outerWidth;
  let outerHeight;
  let pageXOffset;
  let pageYOffset;
  let screen;

  if (typeof window !== 'undefined') {
    innerWidth = window.innerWidth;
    innerHeight = window.innerHeight;
    outerWidth = window.outerWidth;
    outerHeight = window.outerHeight;
    pageXOffset = window.pageXOffset;
    pageYOffset = window.pageYOffset;
    screen = window.screen;
  }

  return {
    domain: DOMAIN,
    timestamp: Date.now(),
    clientSessionId,
    page: {
      app: 'mover',
      environment: ENVIRONMENT.name,
      userAgent: typeof navigator !== 'undefined' && navigator.userAgent,
      url: typeof window !== 'undefined' && window.location.href,
      name: '_',
    },
    details: {
      url_source: source,
      network,
      viewport: {
        // prettier-ignore
          // eslint-disable-next-line no-nested-ternary
          size: innerWidth < 768 ? 'small'
          : innerWidth <= 1024 ? 'medium'
          : 'large',
          innerWidth,
          innerHeight,
          outerWidth,
          outerHeight,
          pageXOffset,
          pageYOffset,
        ...(screen
          ? {
              availWidth: screen.availWidth,
              availHeight: screen.availHeight,
            }
          : {}),
      },
    },
  };
}
