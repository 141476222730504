import {
  buildEnvironmentNames,
  buildEnvironmentFromLocation,
  buildEnvironmentFromStorage,
} from '@updater-core/lib/environment/environment';
import { createGateway } from '@updater-core/gateways/local-storage';
import { environments } from './environments';
import { getEnabledDebugs } from './debug';
import type { Environment } from './types';

const localStorage = createGateway();

export const ENVIRONMENT: Environment =
  buildEnvironmentFromStorage(environments, localStorage) ||
  buildEnvironmentFromLocation(environments);

export const ENVIRONMENTS = buildEnvironmentNames(environments);

try {
  const enabled = getEnabledDebugs();
  if (enabled) {
    if (!ENVIRONMENT.debug) {
      ENVIRONMENT.debug = {};
    }
    Object.assign(ENVIRONMENT.debug, enabled);
  }
} catch (ex) {
  // safety precaution around non-critical logging code
  // eslint-disable-next-line no-console
  console.log(ex);
}

function isCurrentEnv(currentEnv) {
  return (env) => currentEnv === env;
}

const isEnv = isCurrentEnv(ENVIRONMENT.name);

export const IS_UPDATRON = isEnv(ENVIRONMENTS.UPDATRON);
export const IS_DEMO = isEnv(ENVIRONMENTS.DEMO);
export const IS_TEST = isEnv(ENVIRONMENTS.TEST);
export const IS_STAGING = isEnv(ENVIRONMENTS.STAGING);
export const IS_PRODUCTION = isEnv(ENVIRONMENTS.PRODUCTION);
export const IS_DEV = isEnv(ENVIRONMENTS.DEVELOPMENT);
