import { createStore } from '@updater-core/lib/store';
import { loggerMiddleware } from '@updater-core/lib/logger';
import { traceActions } from '@updater-core/lib/tracing';
import { SessionState } from '@updater-core/session';
import * as selectors from './selectors';
import {
  init,
  fetchSession,
  clearSession,
  createSession,
  setSession,
  setResetPassword,
  setForgotPassword,
  setEmail,
  setUUID,
  setVerified,
} from './actions';
import { createLogger } from './logger';
import { SessionInteractor } from './interactor.d';

const { log } = createLogger('[STORE] state update');

export function createInteractor(dependencies): SessionInteractor {
  const store = createStore<SessionState>({} as SessionState, [
    loggerMiddleware(log),
  ]);

  const actionDependencies = {
    ...dependencies,
    store,
  };

  return {
    ...store,
    initialized: (fn) =>
      store.connect((state, done) => {
        if (state?.initialized) {
          fn(state);
          done();
        }
      }),
    actions: {
      ...traceActions({
        name: 'session',
        init: (...args) =>
          init(actionDependencies)(...args).then((response) => response),
        fetchSession: (...args) =>
          fetchSession(actionDependencies)(...args).then(
            (response) => response
          ),
        createSession: (...args) =>
          createSession(actionDependencies)(...args).then(
            (response) => response
          ),
        setSession: (...args) => setSession(actionDependencies)(...args),
        clearSession: clearSession(actionDependencies),
        setResetPassword: setResetPassword(actionDependencies),
        setForgotPassword: setForgotPassword(actionDependencies),
        setUUID: setUUID(actionDependencies),
        setEmail: setEmail(actionDependencies),
        setVerified: setVerified(actionDependencies),
      }),
      log: createLogger('[CONSUMER]').log,
    },
    selectors,
  };
}
