/*
 * Build an object of environment names in the form:
 *
 *  {
 *    PRODUCTION: 'production',
 *    STAGING: 'staging',
 *  }
 *
 *  Takes a single `environments` configuration object in the form:
 *
 *  {
 *    production: {
 *      name: 'production',
 *      endpoints: { ... }
 *      keys: { ... }
 *    }
 *  }
 */
export function buildEnvironmentNames(environments) {
  if (!isFilledObject(environments)) {
    throw new Error(
      `[buildEnvironmentNames] No environments found in: ${environments}`
    );
  }

  return Object.keys(environments).reduce(reduceToName, {});

  function reduceToName(current, key) {
    const NAME = environments[key].name;
    return NAME
      ? ({ ...current, [NAME.toUpperCase()]: NAME})
      : current;
  }
}

function isFilledObject(obj) {
  return obj && obj instanceof Object && Object.keys(obj).length;
}
