import { ENVIRONMENT } from '@updater-core/lib/environment';
import { cloudinarySrc } from './cloudinary';

const rawStringsById = {
  'asset-protect': ['Asset Protect'],
  assurant: ['Assurant'],
  bader: ['Bader'],
  'beecher-carlson': ['Beecher Carlson'],
  'bridge-liability-coverage': ['Bridge Liability Coverage'],
  'brightway-insurance': ['Brightway Insurance'],
  'community-indemnity-shield': ['Community Indemnity Shield'],
  corelogic: ['CoreLogic', `Renter's Insurance Select (By CoreLogic)`],
  'deans-and-homer': ['Deans and Homer'],
  'effective-coverage': ['Effective Coverage'],
  epremium: [
    'ePremium',
    'ePremium, but you may choose the provider that best fits your needs',
  ],
  erenterplan: [
    'eRenter Plan',
    'eRenterPlan',
    'eRenterPlan.com',
    'eRenterplan',
    'eRentersPlan',
  ],
  'farmers-insurance': [`Farmer's Insurance`],
  'first-american': [
    'First American',
    'First American Property & Casualty Group',
    'First American Property Insurance',
  ],
  jetty: ['Jetty'],
  'leaseterm-solutions': ['LeaseTerm Solutions'],
  leasingdesk: ['LeasingDesk', 'LeasingDesk Insurance'],
  lemonade: ['Lemonade'],
  'multifamily-insurance-partners': ['Multifamily Insurance Partners'],
  'new-castle-insurance': ['New Castle Insurance'],
  'occupant-shield': ['Occupant Shield'],
  propertyprotect: ['PropertyProtect'],
  'protection-choice': ['Protection Choice'],
  rll: ['RLL'],
  'renters-legal-liability': [
    'Renters Legal Liability',
    'Renters Legal Liability LLC',
  ],
  residentinsure: ['ResidenInsure', 'Resident Insure', 'ResidentInsure'],
  residentshield: ['Resident Shield', 'ResidentShield'],
  'roostr-renters-insurance': [`Roost Renter's Insurance`],
  selectquote: [
    'Provider of your choice',
    'Renters Insurance',
    'Resident Liability Insurance',
    'SelectQuote',
    'choosing the provider that best fits your needs',
    'choosing the provider that best fits your needs.',
  ],
  'skinner-select-master-liability-program': [
    'Skinner Select Master Liability Program',
  ],
  'state-farm': ['State Farm', 'State Farm - Adam Hutcheon Agency'],
  'stern-risk': [
    'Stern Risk',
    'Stern Risk Partners',
    'Stern Risk Partners LLC',
  ],
  sure: ['Sure'],
};

const idToName = {
  'farmers-insurance': 'Farmer’s Insurance',
  sure: 'Sure',
  'deans-and-homer': 'Deans and Homer',
  'occupant-shield': 'Occupant Shield',
  selectquote: 'SelectQuote',
  'roost-renters-insurance': 'Roost Renter’s Insurance',
  'new-castle-insurance': 'New Castle Insurance',
  residentshield: 'ResidentShield',
  'asset-protect': 'Asset Protect',
  assurant: 'Assurant',
  'beecher-carlson': 'Beecher Carlson',
  'protection-choice': 'Protection Choice',
  'leaseterm-solutions': 'LeaseTerm Solutions',
  residentinsure: 'ResidentInsure',
  erenterplan: 'eRenterPlan',
  rll: 'RLL',
  'multifamily-insurance-partners': 'Multifamily Insurance Partners',
  jetty: 'Jetty',
  'state-farm': 'State Farm',
  corelogic: 'CoreLogic',
  'first-american': 'First American',
  leasingdesk: 'LeasingDesk',
  'effective-coverage': 'Effective Coverage',
  epremium: 'ePremium',
  'renters-legal-liability': 'Renters Legal Liability',
  propertyprotect: 'PropertyProtect',
  lemonade: 'Lemonade',
  'stern-risk': 'Stern Risk',
  bader: 'Bader',
  'brightway-insurance': 'Brightway Insurance',
  'skinner-select-master-liability-program':
    'Skinner Select Master Liability Program',
  'community-indemnity-shield': 'Community Indemnity Shield',
  'bridge-liability-coverage': 'Bridge Liability Coverage',
};

const cloudinaryEndpoint = ENVIRONMENT.endpoints.cloudinary;

function getLogoSrc(logoID) {
  return `/legacy/insurance/${logoID}.png`;
}

/**
 * if there is matched id in our hardcoded config -> return the values from our mapping
 * if the provider id is not in our mapping, return the values from the insurance config
 * @param {*} id
 * @param {*} config
 * @returns {Object} prefferredProvider
 */

export function idToConfig(id, config) {
  return {
    id: id || config?.name,
    name: id ? idToName[id] : config?.name,
    logo: id
      ? getLogoSrc(id)
      : cloudinarySrc({
          baseUrl: cloudinaryEndpoint,
          format: 'c_fill,w_150',
          filename: config?.logo_id,
        }),
  };
}

export function entityConfigToInsuranceConfig(config) {
  let { name = '' } = config || {};
  const {
    jetty_enabled: jettyEnabled = false,
    required = false,
    requirements,
    exclude_sure: excludeSure = false,
    ...rest
  } = config || {};

  if (name.match(/.*assurant.*/i)) {
    name = 'Assurant';
  } else if (jettyEnabled && name.match(/.*erenterplan.*/i)) {
    name = 'eRenterPlan';
  } else if (jettyEnabled && name.match(/.*effective.*coverage.*/i)) {
    name = 'Effective Coverage';
  } /*
     * Now that sure is always enabled we're removing this check.
     * TODO: more work on this logic will be needed as right now it's
     * up in the air if we want any of these ifs
  else if (jettyEnabled) {
    name = 'Sure';
  }
  */

  const id = Object.keys(rawStringsById).find((partnerId) =>
    rawStringsById[partnerId].includes(name)
  );

  const hasPreferredProvider = id !== undefined || config?.name !== undefined;

  return {
    ...(hasPreferredProvider
      ? {
          preferredProvider: {
            ...idToConfig(id, config),
            url: config?.cta_url,
          },
        }
      : {
          defaultProvider: {
            ...idToConfig('selectquote'),
            url: config?.cta_url,
          },
        }),
    ...(requirements
      ? { requirements: requirements?.map((str) => str.replace(/\\"/g, '"')) }
      : {}),
    required,
    excludeSure,
    config: rest,
  };
}

export function getInsuranceEntityConfig(itemDefinitions) {
  return itemDefinitions?.find(
    ({ code }) => code === 'insurance' || code === 'renters_insurance'
  )?.page;
}
