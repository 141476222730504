/* eslint-disable camelcase */
import isAfter from 'date-fns/isAfter';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isValid from 'date-fns/isValid';
import {
  entityConfigToInsuranceConfig,
  getInsuranceEntityConfig,
} from '@updater-core/utils/insurance-config';
import {
  AddressHomeSize,
  Entity,
  EventStreamAttributeConfiguration,
  HomeSizeValue,
  ItemDefinitionPhase,
  MoveDirections,
  MovesReferenceObject,
  SessionAddress,
  SessionRequestHeader,
  UtilityFeature,
  RawSessionPayLoadCurrentMove,
  SessionState,
} from '@updater-core/session';

export function fromHomeSize(state: SessionState): AddressHomeSize {
  return state?.user?.current_move?.from_address?.metadata?.home_size;
}

export function fromStreet(state: SessionState): string {
  return state?.user?.current_move?.from_address?.street;
}

export function fromUnit(state: SessionState): string | null {
  return state?.user?.current_move?.from_address?.apt;
}

export function fromCity(state: SessionState): string {
  return state?.user?.current_move?.from_address?.city;
}

export function fromState(state: SessionState): string {
  return state?.user?.current_move?.from_address?.state;
}

export function fromZip(state: SessionState): string {
  return state?.user?.current_move?.from_address?.zip;
}

export function fromClassification(state: SessionState): string {
  return state?.user?.current_move?.from_address?.classification;
}

export function fromMetadata(state: SessionState): Object | null {
  return state?.user?.current_move?.from_address?.metadata;
}

export function toHomeSize(state: SessionState): AddressHomeSize {
  return state?.user?.current_move?.to_address?.metadata?.home_size;
}

export function toStreet(state: SessionState): string {
  return state?.user?.current_move?.to_address?.street;
}

export function toUnit(state: SessionState): string | null {
  return state?.user?.current_move?.to_address?.apt;
}

export function toCity(state: SessionState): string {
  return state?.user?.current_move?.to_address?.city;
}

export function toState(state: SessionState): string {
  return state?.user?.current_move?.to_address?.state;
}

export function toZip(state: SessionState): string {
  return state?.user?.current_move?.to_address?.zip;
}

export function toClassification(state: SessionState): string {
  return state?.user?.current_move?.to_address?.classification;
}

export function toMetadata(state: SessionState): Object | null {
  return state?.user?.current_move?.to_address?.metadata;
}

export function toOwnership(state: SessionState): string {
  return state?.user?.current_move?.to_address?.ownership;
}

export function toStatus(state: SessionState): string {
  return state?.user?.current_move?.to_address?.status;
}

function todayIsAfterDate(state: SessionState): boolean {
  const moveDateString = state?.user?.current_move?.date;
  if (!moveDateString) return undefined;
  const moveDate = new Date(moveDateString);
  return isAfter(Date.now(), moveDate);
}

function signedUpAfterDate(state: SessionState): boolean {
  const moveDateString = state?.user?.current_move?.date;
  const signUpDateString = state?.user?.signed_up_at;
  if (!moveDateString || !signUpDateString) return undefined;
  const moveDate = new Date(moveDateString);
  const signUpDate = new Date(signUpDateString);
  return isAfter(signUpDate, moveDate);
}

function convertMoveDate(moveDateString): Date | null {
  try {
    const [year, month, day] = (moveDateString || '')
      .split('-')
      .map((str) => parseInt(str, 10));
    const moveDate = new Date(year, month - 1, day);
    return moveDate;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('move_date_conversion', e);
    return null;
  }
}

function daysTillDate(state: SessionState): number {
  const moveDateString = state?.user?.current_move?.date;
  const moveDate = convertMoveDate(moveDateString);

  if (!isValid(moveDate)) return undefined;

  return differenceInCalendarDays(moveDate, new Date());
}

function distanceFromSignUpToDate(state: SessionState): number {
  const moveDateString = state?.user?.current_move?.date;
  const moveDate = convertMoveDate(moveDateString);

  const signUpDateString = state?.user?.signed_up_at;
  const signUpDate = new Date(signUpDateString);

  if (!isValid(moveDateString) || !isValid(signUpDateString)) return undefined;
  return differenceInCalendarDays(moveDate, signUpDate);
}

export function entities(state: SessionState): Entity[] {
  const siteEntities = state?.site_config?.entities;
  if (!siteEntities || !siteEntities.length) {
    return [];
  }

  return siteEntities;
}

export function company(state: SessionState): Entity {
  return entities(state).find(({ is_company: isCompany }) => isCompany);
}

function entityIdString(state: SessionState): string {
  return entities(state)
    .map(({ id }) => `[${id}]`)
    .join(',');
}

export function itemDefinitions(state: SessionState) {
  return state?.site_config?.item_definitions;
}

export function currentMove(state: SessionState): RawSessionPayLoadCurrentMove {
  return state?.user?.current_move;
}

export function journey(state: SessionState): any {
  return currentMove(state)?.journey;
}

export function currentMoveId(state: SessionState): string {
  return currentMove(state)?.id;
}

export function currentMoveDate(state: SessionState): string {
  const move = currentMove(state);
  return move?.date;
}

// From Address

export function currentMoveFromAddress(state: SessionState): SessionAddress {
  const move = currentMove(state);
  return { ...move?.from_address };
}

export function currentMoveFromAddressHomeSize(
  state: SessionState
): HomeSizeValue {
  const address = currentMoveFromAddress(state);
  return address?.metadata?.home_size;
}

// To Address

export function currentMoveToAddress(state: SessionState): SessionAddress {
  const move = currentMove(state);
  return { ...move?.to_address };
}

export function currentMoveToAddressHomeSize(
  state: SessionState
): HomeSizeValue {
  const address = currentMoveToAddress(state);
  return address?.metadata?.home_size;
}

export function currentMoveDirection(state: SessionState): MoveDirections {
  const move = currentMove(state);
  return move?.direction;
}

export function currentMoveDirectionType(state: SessionState): string {
  const move = currentMove(state);
  return move?.directionType;
}

export function currentMoveVerified(state: SessionState): boolean {
  const move = currentMove(state);
  return move?.is_verified;
}

export function currentMoveCreatedAt(state: SessionState): string {
  const move = currentMove(state);
  return move?.created_at;
}

export function rentersInsurance(state: SessionState) {
  return entityConfigToInsuranceConfig(
    getInsuranceEntityConfig(itemDefinitions(state))
  );
}

export function eventStreamAttributes(
  state: SessionState
): EventStreamAttributeConfiguration {
  const insurance = rentersInsurance(state);
  const current_move_created_at = currentMoveCreatedAt(state);
  const division_id = divisionId(state) || '';
  return {
    channel: state?.site_config?.channel?.name,
    entityIds: entityIdString(state),
    division_id,
    user: {
      email: state?.user?.email,
      uuid: state?.user?.uuid,
      // How to get inviteId?
      moveId: state?.user?.current_move_id?.toString(),
      test: state?.user?.test,
      move: {
        id: state?.user?.current_move_id?.toString(),
        ownership: state?.user?.current_move?.to_address?.ownership,
        direction: state?.user?.current_move?.direction,
        days_from_signup_to_move_date: distanceFromSignUpToDate(state),
        days_to_move_date: daysTillDate(state),
        signed_up_after_move_date: signedUpAfterDate(state),
        today_is_after_move_date: todayIsAfterDate(state),
        to_home_size: toHomeSize(state),
        from_home_size: fromHomeSize(state),
        journey: state?.user?.current_move?.journey,
        to_status: toStatus(state),
        to_state: toState(state),
        created_at: current_move_created_at
          ? new Date(current_move_created_at).getTime()
          : undefined,
        available_item_codes: (itemDefinitions(state) || [])
          .map(({ code }) => `[${code}]`)
          .join(','),
        holdback:
          state?.user?.current_move?.metadata?.optimizely_holdback ?? false,
      },
      renters_insurance: {
        preferred_provider:
          // Use in operator to discriminate between union types
          // https://www.typescriptlang.org/docs/handbook/advanced-types.html
          ('preferredProvider' in insurance &&
            insurance?.preferredProvider?.id) ||
          '',
      },
    },
  };
}

export function uuid(state: SessionState): string {
  return state?.user?.uuid;
}

export function userId(state: SessionState): string {
  return state?.user?.id;
}

export function test(state: SessionState): boolean {
  return state?.user?.test;
}

export function signUpDateTime(state: SessionState): string {
  return state?.user?.signed_up_at;
}

export function siteEntityId(state: SessionState): string {
  return state?.user?.site_entity?.id;
}

export function siteEntityName(state: SessionState): string {
  return state?.user?.site_entity?.name;
}

export function siteEntitySubdomain(state: SessionState): string {
  return state?.user?.site_entity?.subdomain;
}

export function items(state: SessionState): any[] {
  return state?.user?.current_move?.items;
}

export function getItemByKind(
  kind: string,
  state: SessionState
): Object | null {
  return (
    state?.user?.current_move?.items?.filter((item) => item.kind === kind)[0] ||
    null
  );
}

export function itemDefinitionPhases(
  state: SessionState
): ItemDefinitionPhase[] {
  return state?.site_config?.item_definition_phases;
}

export function declinedItems(state: SessionState): Object | null {
  return state?.user?.current_move?.declined_item_types;
}

export function isAuthenticated(state: SessionState): boolean {
  return Boolean(state && state.uid);
}

export function isUnauthenticated(state: SessionState): boolean {
  return state?.code === 'UNAUTHORIZED';
}

export function isLockedOut(state: SessionState): boolean {
  return state?.code === 'ACCESS_LOCKED';
}

export function hasResetPassword(state: SessionState): boolean {
  return state?.reset;
}

export function hasForgotPassword(state: SessionState): boolean {
  return state?.forgot;
}

export function requestHeaders(state: SessionState): SessionRequestHeader {
  return {
    uid: state?.uid,
    client: state?.client,
    'access-token': state?.['access-token'],
    app: state?.user?.app,
  };
}

export function channelName(state: SessionState): string {
  return state?.site_config?.channel?.name;
}

export function isPropertyManagement(state: SessionState): boolean {
  return ['Property Management', 'property_management'].includes(
    channelName(state)
  );
}

export function channelClientName(state: SessionState): string {
  return state?.site_config?.channel?.client_name;
}

export function channelDivisionName(state: SessionState): string {
  return state?.site_config?.channel?.division_name;
}

export function userFirstName(state: SessionState): string {
  return state?.user?.first_name;
}

export function userMiddleName(state: SessionState): string {
  return state?.user?.middle_name;
}

export function userLastName(state: SessionState): string {
  return state?.user?.last_name;
}

export function userFullName(state: SessionState): string {
  return [userFirstName(state), userLastName(state)].filter(Boolean).join(' ');
}

export function userNames(state: SessionState): string[] | null {
  return state?.user?.names;
}

export function userPhoneNumber(state: SessionState): string | null {
  return state?.user?.home_phone || state?.user?.cell_phone;
}

export function userEmail(state: SessionState): string {
  return state?.user?.email;
}

export function userMetadata(state: SessionState): Object | null {
  return state?.user?.metadata;
}

export function isHomeOwner(state: SessionState): boolean {
  const { to_address } =
    currentMove(state) || ({} as RawSessionPayLoadCurrentMove);
  return to_address?.ownership === 'owner';
}

export function moves(state: SessionState): MovesReferenceObject {
  return state?.user?.moves;
}

// No idea what this returns but odds are it really needs to get typed.
export function privacyPolicies(state: SessionState) {
  return state?.site_config?.privacy_policies;
}

export function dashboardBannerHtml(state: SessionState): string {
  return state?.site_config?.dashboard?.banner_html;
}

export function residentRepositoryFlag(state: SessionState): boolean {
  const hasCompanyEnabledResidentRepository = Boolean(
    state?.user?.site_entity?.parent?.config?.features?.resident_repository
  );

  const hasDivisionEnabledResidentRepository = Boolean(
    state?.user?.site_entity?.config?.features?.resident_repository
  );

  return (
    hasCompanyEnabledResidentRepository && hasDivisionEnabledResidentRepository
  );
}

export function residentOnboardingFlag(state: SessionState): boolean {
  const childFlag =
    state.user?.site_entity?.config?.features?.resident_onboarding;
  if (childFlag === true || childFlag === false) {
    return childFlag;
  }
  return !!state?.user?.site_entity?.parent?.config?.features
    ?.resident_onboarding;
}

export function utilityFeatures(state: SessionState): UtilityFeature {
  return state?.user?.site_entity?.config?.features?.utilities;
}

export function divisionId(state: SessionState): string | null {
  return (
    state?.site_config?.entities.find(({ is_division }) => is_division)?.id ??
    null
  );
}
