import { StorageGateway } from '@updater-core/gateways/interfaces';
import { Environments, Environment } from '../types';
import { buildEnvironment } from './build-environment';

const STORAGE_KEY = 'upd.environment_name_override';

export function buildEnvironmentFromStorage(
  environments: Environments,
  storageGateway: StorageGateway
): Environment | undefined {
  const envNameInStorage = storageGateway.get(STORAGE_KEY);
  if (envNameInStorage) {
    return buildEnvironment(environments, { env: envNameInStorage });
  }
  return undefined;
}
