import merge from 'lodash/merge';
import { parseUrlSearchString, getLastFromStringOrArray } from './utils';
import { DEFAULT_ENV, ENVIRONMENTS } from './constants';
import { Environments } from '../types';

export function buildEnvironment(
  environments = {},
  { env, extendEnv }: { env?: string; extendEnv?: string } = {}
) {
  const envs: Environments = { ...environments };

  return merge({}, envs.base, envs[env] || envs[DEFAULT_ENV], envs[extendEnv]);
}

export function getBuildOptionsFromLocation(location) {
  // eslint-disable-next-line no-param-reassign
  location = location || {};
  const search = parseUrlSearchString(location.search);
  const hostnameEnv = getEnvironmentFromHostname(location.hostname);

  // using `merge` over `Object.assign` because merge doesn't
  // add properties that have a value of `undefined`.
  return merge(
    {},
    {
      env: getLastFromStringOrArray(search.env) || hostnameEnv,
      extendEnv: getLastFromStringOrArray(search['extend-env']),
    }
  );
}

// Used internally, exporting for testing purposes only
export function getEnvironmentFromHostname(hostname = '') {
  const parts = (hostname || '').split('.').reverse();
  const domain = parts[1];

  switch (domain) {
    case 'updater': {
      if (parts.includes('poweredby')) {
        // Similar to the default case, but poweredby URLs have an extra part
        // (e.g. app.poweredby.staging.updater.com)
        return parts.length > 4 ? parts[2] : ENVIRONMENTS.PRODUCTION;
      }
      // Return either the env (ex. `staging`) or `updater` (i.e. production)
      // Need to do this since env hosts have 4 parts and production only has 3
      return parts.length > 3 ? parts[2] : ENVIRONMENTS.PRODUCTION;
    }

    case ENVIRONMENTS.DOCKER:
    case ENVIRONMENTS.RUNNABLE:
    case ENVIRONMENTS.UPDATRON:
      return domain;

    default:
      return DEFAULT_ENV;
  }
}
