import { ENVIRONMENT } from '@updater-core/lib/environment';
import { createLoggerFunc } from '@updater-core/lib/logger';

export function createLogger(...args) {
  return createLoggerFunc(
    ENVIRONMENT.debug?.session,
    '[SESSION]',
    'color: #aba0fd'
  )(...args);
}

export const logger = createLogger();
