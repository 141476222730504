/* eslint-disable no-console */
import { StorageGateway } from '../interfaces';

function get(id: string) {
  try {
    if (typeof window === 'undefined') {
      return '';
    }
    return localStorage.getItem(id);
  } catch (e) {
    console.error(`Failed to get ${id}`);
    console.error(e);
    return false;
  }
}

function set(id: string, value: any) {
  try {
    if (typeof window === 'undefined') {
      return false;
    }
    localStorage.setItem(id, value);
    return true;
  } catch (e) {
    console.error(`Failed to set ${id} to ${value}`);
    console.error(e);
    return false;
  }
}

const LocalStorageGateway: StorageGateway = {
  keys: () => {
    const keys = [];

    for (let i = 0; i < localStorage.length; i += 1) {
      keys.push(localStorage.key(i));
    }

    return keys;
  },
  get,
  set,
  getValue: ({ key }) => get(key),
  setValue: ({ key, value }) => set(key, value),
  remove: (id) => {
    if (typeof id === 'object') {
      // eslint-disable-next-line no-param-reassign
      id = id?.key;
    }
    localStorage.removeItem(id);
    return true;
  },
  getJSON: (id) => {
    try {
      if (typeof window === 'undefined') {
        return '{}';
      }
      return JSON.parse(localStorage.getItem(id));
    } catch (e) {
      console.error(`Failed to get ${id} as JSON.`);
      console.error(e);
      return null;
    }
  },
  setJSON: (id, value) => {
    try {
      if (typeof window === 'undefined') {
        return false;
      }
      const stringified = JSON.stringify(value);
      localStorage.setItem(id, stringified);
      return true;
    } catch (e) {
      console.error(`Failed to set ${id} to ${value} as JSON.`);
      console.error(e);
      return false;
    }
  },
  subscribe: (id, callback) => {
    try {
      if (typeof window === 'undefined') {
        return false;
      }

      window.addEventListener('storage', (event) => {
        if (event.key === id) {
          callback({ newValue: event.newValue });
        }
      });

      return true;
    } catch (e) {
      console.error(`Failed to listen to storage events for ${id}.`);
      console.error(e);
      return false;
    }
  },
  subscribeJSON: (id, callback) => {
    try {
      if (typeof window === 'undefined') {
        return false;
      }

      window.addEventListener('storage', (event) => {
        if (event.key === id) {
          callback({ newValue: JSON.parse(event.newValue) });
        }
      });

      return true;
    } catch (e) {
      console.error(`Failed to listen to storage events for ${id}.`);
      console.error(e);
      return false;
    }
  },
};

export function createGateway(): StorageGateway {
  return LocalStorageGateway;
}
