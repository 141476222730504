// Used internally, exporting for testing purposes only
export function getLastFromStringOrArray(stringOrArray) {
  return [].concat(stringOrArray).pop();
}

// Used internally, exporting for testing purposes only
//
// This method mimicks the Angular 1.x parsing for $location.search()... for the most part.
// It differs in that the Angular parser sets the value to `true` for key/value pairs
// without an `=`. This method sets the value to `null` as the spec
// states: https://www.w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
export function parseUrlSearchString(locationSearchString = '') {
  // Transforms a string `?test1=test1&test2=test2&test1=anothertest1` into an object
  //
  //  {
  //    test1: ['test1', 'anothertest1'],
  //    test2: 'test2'
  //  }
  //
  //
  return locationSearchString
    .trim()
    .replace(/^\?/, '')
    .split('&')
    .reduce(reducePairsToObject, {});

  function reducePairsToObject(ret, pair) {
    const parts = pair.split('=');
    const key = tryDecodeURIComponent(parts[0]);
    // https://www.w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
    const val = parts[1] ? tryDecodeURIComponent(parts[1]) : null;

    if (key) {
      /* eslint-disable-next-line no-param-reassign, no-prototype-builtins */
      ret[key] = ret.hasOwnProperty(key) ? [].concat(ret[key], val) : val;
    }

    return ret;
  }
}

function tryDecodeURIComponent(value) {
  try {
    return decodeURIComponent(value);
  } catch (e) {
    // Ignore any invalid URI component.
    return undefined;
  }
}
