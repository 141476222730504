import { Environment } from '../types';
import { DC_STAGING } from './keys';

export const test: Environment = {
  name: 'test',
  endpoints: {
    api: {
      url: '//api.staging.updater.com',
    },
    cloudinary: 'https://res.cloudinary.com/updater-staging/image/upload',
    optimizely:
      'https://cdn.optimizely.com/datafiles/PV8bgAg9tPhvowmGw4zz4X.json',
    // eventstream: 'http://eventstream-api.dev.updater.com/xyz',
    sure: {
      protections:
        'https://api.trysureapp.com/api/partner/v1.1/protections/renters',
      partner: 'https://api.trysureapp.com/api/partner/v1',
    },
    leasingDeskWidget: 'https://ri-widget-uat-demo.realpage.com/widget',
    verticalOne: 'https://api-staging.verticalone.com/v1',
    dollyUi: 'https://book-staging.dolly.com/',
    dc: 'https://api.staging.bridgevine.io/v6',
    tokenService: {
      url: 'https://v8vy0byloh.execute-api.us-east-1.amazonaws.com',
    },
    consumerAppUrl: 'https://consumer-app.staging.updater.com',
    bucketingServiceUrl: '//bucketing-service-staging.updatron.workers.dev',
  },
  keys: {
    smartystreets: {
      authToken: '77240726',
    },
    stripe: 'pk_test_KZhy8xHQcPTYedpuiPekDh8w',
    segment: 'f2ZJEsBwVimRzNsLmTl4ys4G10T5Ntsu',
    sentry: 'https://9593b71c96944d34965c760f1ce50681@sentry.io/53544',
    eventstream: {
      authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55IjoiVXBkYXRlciIsImVtYWlsIjoiYWRtaW5AdXBkYXRlci5jb20iLCJuYW1lIjoiVXBkYXRlciBBZG1pbiIsImlhdCI6MTUwNDEwMTYzMiwiaXNzIjoiVXBkYXRlciJ9.vWh5iZY_npjI5o9vDCHDvAygbuhpn5fL64uGnCaeF_Q',
    },
    selectquote: {
      authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQHVwZGF0ZXIuY29tIiwic3ViIjoiY2xpY2tzdHJlYW0iLCJpYXQiOjE1NDAzMTAzNzMsImlzcyI6IlVwZGF0ZXIifQ.QvQewiy9QX031Iw2Zo9uD-zE-le42EXxeM-_1RkmoU8',
    },
    sure: 'Token pRLyZdYX3EsuNm6en7rBhFDokItCqfiMgb8x9SQAcwazTpHG0',
    sureStripe: 'pk_test_D2bzH96t4WolPemRrrmHXjoT',
    dc: {
      authorization: DC_STAGING,
    },
  },
  debug: {
    appShell: false,
    appShellRouter: false,
    signInApp: false,
    session: false,
    optimizely: false,
    eventTracker: false,
    graphQl: false,
    moverAppRedux: false,
    utilitiesMarket: false,
    tvInternetApp: false,
    tvInternetCheckout: false,
    shoppingCart: false,
    webpush: false,
    headerApp: false,
    footerComponent: false,
    optimizelyModule: false,
    utilsConfirmation: false,
    utilsDashboard: false,
    quickSetup: false,
    forwardMail: false,
    verify: false,
    creditCard: false,
    insurance: false,
    signUp: true,
    moveYourStuff: true,
    packingSupplies: true,
    extraHelp: false,
    tviBuyFlowHelpers: false,
  },
  integrations: {
    disableRaven: false,
  },
};
