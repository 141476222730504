export function cloudinarySrc({
  baseUrl,
  format,
  filename,
  filetype = 'jpg',
}: {
  baseUrl?: string;
  format?: string;
  filename?: string;
  filetype?: string;
}): string {
  return `${baseUrl}/${format}/${filename}.${filetype}`;
}

export function generateCloudinaryUrl(filename, envName) {
  const env =
    envName === 'development' ||
    envName === 'docker' ||
    envName === 'demo' ||
    envName === 'updatron' ||
    envName === 'test'
      ? 'staging'
      : envName;
  return `//res.cloudinary.com/updater-${env}/image/upload/${filename}`;
}
