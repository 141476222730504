import gql from 'graphql-tag';
import { fetchGraphQLQuery } from '@updater-core/lib/graphql';
import { ENVIRONMENT } from '@updater-core/lib/environment';
import { isFeatureEnabled, attachUserId } from '@updater-core/lib/optimizely';
import { redirectIntoQuickSetup } from '@updater-core/redirects/quickSetup';
import '@updater-core/uds/styles';

function continueOntoMoverApp() {
  return Promise.all([
    import('./shell'),
    import('@updater-core/lib/tracing'),
  ]).then(([{ build }, { startSpan }]) => {
    const span = startSpan('shell.build');
    build();
    span.finish();
  });
}

function goToNextApp(inviteToken) {
  const search = window.location.search
    ? `&${window.location.search.slice(1)}`
    : '';
  window.location.href = `${window.location.origin}/n/invite?inviteToken=${inviteToken}${search}`;
}

function getInviteByRoute() {
  const pathParamInviteToken = (window.location.pathname.match(
    /[0-9a-f-]{36}/g
  ) || [])[0];
  if (pathParamInviteToken) {
    return pathParamInviteToken;
  }
  return undefined;
}

const inviteToken = getInviteByRoute();

function checkInviteAndRouteToApp() {
  return fetchGraphQLQuery({
    url: `${ENVIRONMENT.endpoints.api.url}/graphql`,
    query: gql`
      query inviteByToken($token: String!) {
        inviteByToken(token: $token) {
          invite {
            uuid
            status
          }
        }
      }
    `,
    variables: {
      token: inviteToken,
    },
  })
    .then(async (response) => {
      const uuid = response?.data?.inviteByToken?.invite?.uuid;
      const status = response?.data?.inviteByToken?.invite?.status;

      if (status === 'CLAIMED' || !uuid) {
        const redirected = await redirectIntoQuickSetup();

        if (!redirected) {
          return;
        }
      }

      attachUserId(uuid);
      const enabled = await isFeatureEnabled('new_signup_ui_flag');
      if (enabled) {
        goToNextApp(inviteToken);
      }
    })
    .then(continueOntoMoverApp, continueOntoMoverApp);
}

async function loadApp() {
  if (window.location.pathname.startsWith('/invite') && inviteToken) {
    return checkInviteAndRouteToApp();
  }

  if (window.location.pathname.startsWith('/claim-redeem-offer')) {
    return continueOntoMoverApp();
  }

  const redirected = await redirectIntoQuickSetup();

  if (!redirected) {
    continueOntoMoverApp();
  }
  return undefined;
}

loadApp();
