import merge from 'lodash/merge';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import { TRACKED_EVENTS } from './constants';
import { camelFlattenKeys, parseAnalyticsDataFromEvent } from './utils';

export function createObservable() {
  let callbacks = [];

  function update(...args) {
    return Promise.all(callbacks.map((callback) => callback(...args)));
  }

  function subscribe(fn) {
    callbacks.push(fn);

    return () => {
      callbacks = callbacks.filter((val) => val !== fn);
    };
  }

  const observable = {
    update,
    subscribe,
  };

  return observable;
}

// DEPRECATED: It's possible that some code uses function attributes in track
// calls. We don't want to do this anymore, but this `resolve` function still exists to
// resolve and warn if they are detected.
export function resolve(obj) {
  const keyValues = Object.keys(obj).map((key) => {
    let value = obj[key];

    if (isNil(value)) {
      return [key, value];
    }

    if (isArray(value)) {
      return [key, value];
    }

    if (isFunction(value)) {
      // eslint-disable-next-line no-console
      console.warn(
        '[EVENT] DEPRECATED. Track functions no longer accept function attributes, will resolve for now though.'
      );
      value = value();
    }

    if (isObject(value)) {
      value = resolve(value);
    }

    return [key, value];
  });

  return keyValues.reduce(
    (newEvent, [key, value]) => ({
      ...newEvent,
      [key]: value,
    }),
    {}
  );
}

export function createEventTracker() {
  const observable = createObservable();
  let attributes = {};

  // DEPRECATED: We are keeping this function  here for legacy
  // reasons as some parts of our app still rely on `getAttributes` for access
  // to certain global attributes. We want to move away from this pattern.
  function setAttributes(newAttributes) {
    attributes = newAttributes;
  }

  // DEPRECATED: We are keeping this function  here for legacy
  // reasons as some parts of our app still rely on `getAttributes` for access
  // to certain global attributes. We want to move away from this pattern.
  function getAttributes() {
    // eslint-disable-next-line no-console
    console.warn(
      '[EVENT] getAttributes will soon be deprecated, please retrieve attributes from the original source.'
    );
    return attributes;
  }

  function trackOn(element, callback) {
    const removeListeners = Object.keys(TRACKED_EVENTS).map((eventType) => {
      function handler(domEvent) {
        const targetElementData = parseAnalyticsDataFromEvent(domEvent);
        const object = targetElementData.event;

        if (!object) return;

        const extraDetails = targetElementData.properties;
        const detail = TRACKED_EVENTS[eventType].detail && domEvent.detail;

        // TODO: This feature is hardly used at all. We should remove this
        // feature.
        const eventstreamData = targetElementData.eventstream;

        const { verb } = TRACKED_EVENTS[eventType];
        const details = merge(
          {},
          eventstreamData || {},
          camelFlattenKeys(extraDetails || {}),
          detail && !eventstreamData ? { value: detail } : {}
        );

        const trackTimeAttributes = {
          verb,
          object,
          details,
        };

        let event = callback
          ? callback(trackTimeAttributes)
          : trackTimeAttributes;
        // DEPRECATED: It's possible that some code uses function attributes in track
        // calls. We don't want to do this anymore, but this `resolve` function still exists to
        // resolve and warn if they are detected.
        event = resolve(event);

        track(event);
      }

      element.addEventListener(eventType, handler);

      return () => element.removeEventListener(eventType, handler);
    });

    return () => removeListeners.forEach((remove) => remove());
  }

  function track(...trackTimeAttributes) {
    let event = merge({}, ...trackTimeAttributes);
    // DEPRECATED: It's possible that some code uses function attributes in track
    // calls. We don't want to do this anymore, but this function still exists to
    // resolve and warn if they are detected.
    event = resolve(event);
    return observable.update(event);
  }

  return {
    subscribe: observable.subscribe,
    trackOn,
    track,
    // DEPRECATED: We are keeping this function  here for legacy
    // reasons as some parts of our app still rely on `getAttributes` for access
    // to certain global attributes. We want to move away from this pattern.
    setAttributes,
    // DEPRECATED: We are keeping this function  here for legacy
    // reasons as some parts of our app still rely on `getAttributes` for access
    // to certain global attributes. We want to move away from this pattern.
    getAttributes,
  };
}

const instance = createEventTracker();
export const tracker = instance;
export const {
  track,
  trackOn,
  subscribe,
  // DEPRECATED: We are keeping this function  here for legacy
  // reasons as some parts of our app still rely on `getAttributes` for access
  // to certain global attributes. We want to move away from this pattern.
  setAttributes,
  // DEPRECATED: We are keeping this function  here for legacy
  // reasons as some parts of our app still rely on `getAttributes` for access
  // to certain global attributes. We want to move away from this pattern.
  getAttributes,
} = instance;
